export interface IMenuFooter {
  title: string;
  menu: IMenu[];
}
interface IMenu {
  href: string;
  title: string;
  target?: boolean;
  rel?: "dofollow" | "noopener" | "dofollow noopener" | "nofollow" | "nofollow noopener";
}
export const modelMenuFooter: IMenuFooter[] = [
  {
    title: "Sapo.vn",
    menu: [
      {
        title: "Về chúng tôi",
        href: "https://www.sapo.vn/ve-chung-toi.html?utm_campaign=cpn:ve_chung_toi-plm:footer&amp;utm_source=Tu_nhien;utm_medium=internal&amp;utm_content=fm:text_link-km:-sz:&amp;utm_term=&amp;campaign=footer_blog_sapo",
        target: true,
        rel: "dofollow",
      },
      {
        title: "Sapo là gì?",
        href: "https://www.sapo.vn/sapo-la-gi.html?utm_campaign=cpn:sapo_la_gi-plm:footer&utm_source=Tu_nhien&utm_medium=internal&utm_content=fm:text_link-km:-sz:&utm_term=&campaign=footer_blog_sapo",
        target: true,
      },
      {
        title: "Blog Sapo",
        href: "https://www.sapo.vn/blog",
        target: true,
        rel: "noopener",
      },
      {
        title: "Bảng giá",
        href: "https://www.sapo.vn/bang-gia.html?utm_campaign=cpn:bang_gia-plm:footer&utm_source=Tu_nhien&utm_medium=internal&utm_content=fm:text_link-km:-sz:&utm_term=&campaign=footer_blog_sapo",
        target: true,
        rel: "dofollow",
      },
      {
        title: "Tuyển dụng",
        href: "https://tuyendung.sapo.vn/?utm_campaign=cpn:tuyen_dung-plm:footer&utm_source=sapo.vn%2Fblog&utm_medium=referral&utm_content=fm:text_link-km:-sz:&utm_term=&campaign=footer_blog_sapo",
        target: true,
        rel: "noopener",
      },
      {
        title: "Profile Sản Phẩm",
        href: "https://www.sapo.vn/profile-san-pham?utm_campaign=cpn:profile_san_pham-plm:footer&utm_source=Tu_nhien&utm_medium=internal&utm_content=fm:text_link-km:-sz:&utm_term=&campaign=footer_blog_sapo",
        target: true,
      },
    ],
  },

  {
    title: "Sản phẩm",
    menu: [
      {
        title: "Phần mềm quản lý bán hàng",
        href: "https://www.sapo.vn/phan-mem-quan-ly-ban-hang.html?utm_campaign=cpn:san_pham-plm:footer_blog&amp;utm_source=Tu_nhien;utm_medium=internal&amp;utm_content=fm:text_link-km:-sz:&amp;utm_term=&amp;campaign=footer_blog",
        rel: "dofollow",
        target: true,
      },
      {
        title: "Phần mềm bán hàng online",
        href: "https://www.sapo.vn/quan-ly-ban-hang-online.html?utm_campaign=cpn:san_pham-plm:footer_blog&amp;utm_source=Tu_nhien;utm_medium=internal&amp;utm_content=fm:text_link-km:-sz:&amp;utm_term=&amp;campaign=footer_blog",
        rel: "dofollow",
        target: true,
      },
      {
        title: "Thiết kế website bán hàng",
        href: "https://www.sapo.vn/thiet-ke-website-ban-hang.html?utm_campaign=cpn:san_pham-plm:footer_blog&amp;utm_source=Tu_nhien;utm_medium=internal&amp;utm_content=fm:text_link-km:-sz:&amp;utm_term=&amp;campaign=footer_blog",
        rel: "dofollow",
        target: true,
      },
      {
        title: "Phần mềm quản lý nhà hàng",
        href: "https://www.sapo.vn/phan-mem-quan-ly-nha-hang.html?utm_campaign=cpn:san_pham-plm:footer_blog&amp;utm_source=Tu_nhien;utm_medium=internal&amp;utm_content=fm:text_link-km:-sz:&amp;utm_term=&amp;campaign=footer_blog",
        rel: "dofollow",
        target: true,
      },
      {
        title: "Phần mềm bán hàng đa kênh",
        href: "https://www.sapo.vn/omnichannel.html?utm_campaign=cpn:san_pham-plm:footer_blog&utm_source=Tu_nhien&utm_medium=internal&utm_content=fm:text_link-km:-sz:&utm_term=&campaign=footer_blog",
        rel: "dofollow",
        target: true,
      },
      {
        title: "Quản lý & phát triển thương hiệu",
        href: "https://www.sapo.vn/enterprise?utm_campaign=cpn:san_pham-plm:footer_blog&utm_source=Tu_nhien&utm_medium=internal&utm_content=fm:text_link-km:-sz:&utm_term=&campaign=footer_blog",
        rel: "dofollow",
        target: true,
      },
    ],
  },

  {
    title: "Thiết kế website",
    menu: [
      {
        title: "Thiết kế web thương mại điện tử",
        href: "https://www.sapo.vn/thiet-ke-web-thuong-mai-dien-tu.html?utm_campaign=cpn:thiet_ke_website_ban_hang-plm:footer&utm_source=Tu_nhien&utm_medium=referral&utm_content=fm:text_link-km:-sz:&utm_term=&campaign=footer_blog_sapo",
        rel: "dofollow",
        target: true,
      },
      {
        title: "Thiết kế web thời trang",
        href: "https://www.sapo.vn/thiet-ke-web-thoi-trang.html?utm_campaign=cpn:thiet_ke_website_ban_hang-plm:footer&utm_source=Tu_nhien&utm_medium=referral&utm_content=fm:text_link-km:-sz:&utm_term=&campaign=footer_blog_sapo",
        rel: "dofollow",
        target: true,
      },
      {
        title: "Thiết kế web doanh nghiệp",
        href: "https://www.sapo.vn/thiet-ke-web-doanh-nghiep.html?utm_campaign=cpn:thiet_ke_website_ban_hang-plm:footer&utm_source=Tu_nhien&utm_medium=internal&utm_content=fm:text_link-km:-sz:&utm_term=&campaign=footer_blog_sapo",
        rel: "dofollow",
        target: true,
      },
      {
        title: "Thiết kế website bất động sản",
        href: "https://www.sapo.vn/thiet-ke-web-bat-dong-san.html?utm_campaign=cpn:thiet_ke_website_ban_hang-plm:footer&utm_source=Tu_nhien&utm_medium=internal&utm_content=fm:text_link-km:-sz:&utm_term=&campaign=footer_blog_sapo",
        rel: "dofollow",
        target: true,
      },
      {
        title: "Thiết kế website trọn gói",
        href: "https://www.sapo.vn/thiet-ke-website-tron-goi.html?utm_campaign=cpn:thiet_ke_website_ban_hang-plm:footer&utm_source=Tu_nhien&utm_medium=internal&utm_content=fm:text_link-km:-sz:&utm_term=&campaign=footer_blog_sapo",
        rel: "dofollow",
        target: true,
      },
      {
        title: "Thiết kế website giá rẻ",
        href: "https://www.sapo.vn/thiet-ke-website-gia-re.html?utm_campaign=cpn:thiet_ke_website_ban_hang-plm:footer&utm_source=Tu_nhien&utm_medium=internal&utm_content=fm:text_link-km:-sz:&utm_term=&campaign=footer_blog_sapo",
        rel: "dofollow",
        target: true,
      },
      {
        title: "Mẫu website đẹp",
        href: "https://themes.sapo.vn/?utm_campaign=cpn:themes-plm:footer&utm_source=Tu_nhien&utm_medium=referral&utm_content=fm:text_link-km:-sz:&utm_term=&campaign=footer_blog_sapo",
        rel: "dofollow",
        target: true,
      },
    ],
  },
  {
    title: "Giải pháp quản lý",
    menu: [
      {
        title: "Quản lý siêu thị mini",
        href: "https://www.sapo.vn/phan-mem-quan-ly-sieu-thi-mini.html?utm_campaign=cpn:giai_phap_quan_ly-plm:footer_blog&utm_source=Tu_nhien&utm_medium=internal&utm_content=fm:text_link-km:-sz:&utm_term=&campaign=footer_sapo",
        rel: "dofollow",
        target: true,
      },
      {
        title: "Quản lý cửa hàng tạp hóa",
        href: "https://www.sapo.vn/phan-mem-quan-ly-cua-hang-tap-hoa.html?utm_campaign=cpn:giai_phap_quan_ly-plm:footer_blog&utm_source=Tu_nhien&utm_medium=internal&utm_content=fm:text_link-km:-sz:&utm_term=&campaign=footer_sapo",
        rel: "dofollow",
        target: true,
      },
      {
        title: "Quản lý cửa hàng thời trang",
        href: "https://www.sapo.vn/phan-mem-quan-ly-cua-hang-thoi-trang.html?utm_campaign=cpn:giai_phap_quan_ly-plm:footer_blog&utm_source=Tu_nhien&utm_medium=internal&utm_content=fm:text_link-km:-sz:&utm_term=&campaign=footer_sapo",
        rel: "dofollow",
        target: true,
      },
      {
        title: "Quản lý cửa hàng mỹ phẩm",
        href: "https://www.sapo.vn/phan-mem-quan-ly-cua-hang-my-pham.html?utm_campaign=cpn:giai_phap_quan_ly-plm:footer_blog&utm_source=Tu_nhien&utm_medium=internal&utm_content=fm:text_link-km:-sz:&utm_term=&campaign=footer_sapo",
        rel: "dofollow",
        target: true,
      },
      {
        title: "Quản lý quán cafe",
        href: "https://www.sapo.vn/phan-mem-quan-ly-quan-cafe.html?utm_campaign=cpn:giai_phap_quan_ly-plm:footer_blog&utm_source=Tu_nhien&utm_medium=internal&utm_content=fm:text_link-km:-sz:&utm_term=&campaign=footer_sapo",
        rel: "dofollow",
        target: true,
      },
      {
        title: "Quản lý karaoke, quán net",
        href: "https://www.sapo.vn/phan-mem-quan-ly-karaoke-quan-bida-quan-net.html?utm_campaign=cpn:giai_phap_quan_ly-plm:footer_blog&utm_source=Tu_nhien&utm_medium=internal&utm_content=fm:text_link-km:-sz:&utm_term=&campaign=footer_sapo",
        rel: "dofollow",
        target: true,
      },
      {
        title: "Quản lý quán bida",
        href: "https://www.sapo.vn/phan-mem-quan-ly-quan-bida.html?utm_campaign=cpn:giai_phap_quan_ly-plm:footer_blog&utm_source=Tu_nhien&utm_medium=internal&utm_content=fm:text_link-km:-sz:&utm_term=&campaign=footer_sapo",
        rel: "dofollow",
        target: true,
      },
    ],
  },
];

export const modelAddressFooter: IMenuFooter[] = [
  {
    title: "Trợ giúp",
    menu: [
      {
        title: "Trung tâm trợ giúp",
        href: "//support.sapo.vn/?utm_campaign=cpn:support_sapo-plm:footer&utm_source=sapo.vn&utm_medium=referral&utm_content=fm:text_link-km:-sz:&utm_term=&campaign=footer_sapo",
        rel: "nofollow noopener",
        target: true,
      },
      {
        title: "Hình thức thanh toán",
        href: "//support.sapo.vn/cac-hinh-thuc-thanh-toan-khi-su-dung-dich-vu-cua-sapo?utm_campaign=cpn:hinh_thuc_thanh_toan-plm:footer&utm_source=sapo.vn&utm_medium=referral&utm_content=fm:text_link-km:-sz:&utm_term=&campaign=footer_sapo",
        rel: "nofollow noopener",
        target: true,
      },
      {
        title: "Điều khoản & Điều kiện sử dụng",
        href: "//support.sapo.vn/quy-dinh-su-dung?utm_campaign=cpn:quy_sinh_su_dung-plm:footer&utm_source=sapo.vn&utm_medium=referral&utm_content=fm:text_link-km:-sz:&utm_term=&campaign=footer_sapo",
        rel: "nofollow noopener",
        target: true,
      },
      {
        title: "Chính sách bảo mật",
        href: "//support.sapo.vn/chinh-sach-bao-mat?utm_campaign=cpn:chinh_sach_bao_mat-plm:footer&utm_source=sapo.vn&utm_medium=referral&utm_content=fm:text_link-km:-sz:&utm_term=&campaign=footer_sapo",
        rel: "nofollow noopener",
        target: true,
      },
      {
        title: "Liên hệ",
        href: "//www.sapo.vn/lien-he.html?utm_campaign=cpn:lien_he-plm:footer&utm_source=sapo.vn&utm_medium=internal&utm_content=fm:text_link-km:-sz:&utm_term=&campaign=footer_sapo",
        rel: "nofollow noopener",
        target: true,
      },
    ],
  },
  {
    title: "Dịch vụ",
    menu: [
      {
        title: "Đăng ký tên miền",
        href: "//www.sapo.vn/dang-ky-ten-mien.html",
        rel: "noopener",
        target: true,
      },
      {
        title: "Dịch vụ chiến lược nội dung",
        href: "//www.sapo.vn/xay-dung-chien-luoc-noi-dung.html",
        rel: "noopener",
        target: true,
      },
      {
        title: "Dịch vụ chăm sóc nội dung",
        href: "//www.sapo.vn/cham-soc-noi-dung.html",
        rel: "noopener",
        target: true,
      },
    ],
  },
  {
    title: "Thiết bị bán hàng",
    menu: [
      {
        title: "Máy in hóa đơn",
        href: "https://shop.sapo.vn/may-in-hoa-don-may-in-bill",
        rel: "nofollow noopener",
        target: true,
      },
      {
        title: "Máy in mã vạch",
        href: "https://shop.sapo.vn/may-in-ma-vach",
        rel: "nofollow noopener",
        target: true,
      },
      {
        title: "Máy quét mã vạch",
        href: "https://shop.sapo.vn/may-quet-ma-vach",
        rel: "nofollow noopener",
        target: true,
      },
    ],
  },
];
