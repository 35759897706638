import React, { useEffect, useRef } from "react";
import { useRouter } from "next/router";
import styles from "./btnHomeAndScrollTop.module.scss";
import Image from "next/legacy/image";
import { useWindowSize, useWindowPosition } from "./../../util/functionGlobal";
export function ScrollToTop() {
  const ref = useRef<HTMLDivElement>();
  var positionTop = useWindowPosition();
  useEffect(() => {
    if (positionTop > 600 && positionTop) {
      ref.current.style.display = "block";
    } else {
      ref.current.style.display = "none";
    }
  }, [positionTop]);

  const onClickScrollTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };
  return (
    <div className={styles["scroll-top"]} ref={ref} onClick={onClickScrollTop}>
      <Image src={process.env.NEXT_PUBLIC_BASE_PATH + "/images/totop.png"} alt="Lên đầu trang" width="52" height="52" />
    </div>
  );
}

export function BtnHome() {
  const ref = useRef<HTMLDivElement>();
  const router = useRouter();
  const { width } = useWindowSize();
  var positionTop = useWindowPosition();

  useEffect(() => {
    if (positionTop > 600 && positionTop && width <= 768) {
      ref.current.style.display = "block";
    } else {
      ref.current.style.display = "none";
    }
  }, [positionTop, width]);

  const onClickScrollTop = () => {
    router.push("/");
  };
  return (
    <div className={styles["btn-home"]} ref={ref} onClick={onClickScrollTop}>
      <Image src={process.env.NEXT_PUBLIC_BASE_PATH + "/images/icon-back-to-home.png"} alt="Back to home" width="52" height="52" />
    </div>
  );
}
