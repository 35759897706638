import React, { useState, useRef } from "react";
import { useRouter } from "next/router";
import router from "next/router";

export function FormGoogleSheet() {
  const router = useRouter();
  const formRef = useRef(null);
  const scriptUrl = "https://script.google.com/macros/s/AKfycbz72uLBBI8g8AjRJ8o_j3S13G9H9wXfS2Pzs5yIHDbJe0u7kJFZzD4WVznb0Pc6tPY/exec";
  //dev - const scriptUrl = "https://script.google.com/macros/s/AKfycbzIRnslYiuYuCQc3gLVGVd484lFjn9BqgQMOpFYVvnCtpz75Wem-w25vjazgh8Gf93Y/exec"
  const [loading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState("");
  const DateNow = new Intl.DateTimeFormat("vi-VN", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  }).format(Date.now());

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    fetch(scriptUrl, {
      method: "POST",
      body: new FormData(formRef.current),
    })
      .then((res) => {
        console.log("Gửi thành công");
        setLoading(false);
        setFirstName("");
      })
      .catch((err) => console.log(err));
  };
  return (
    <form ref={formRef} onSubmit={handleSubmit} name="google-sheet">
      <input
        type="email"
        required
        autoComplete="off"
        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
        name="Email"
        onChange={(event) => setFirstName(event.target.value)}
        value={firstName}
        placeholder="Nhập email của bạn"
      />
      <input type="hidden" name="Time" value={DateNow} />
      <input type="hidden" value={"https://sapo.vn/" + router.basePath + router.asPath} name="Url_source" />
      <button type="submit" className="btn-registration">
        {loading ? "Đang gửi..." : router.asPath.includes("/ebook") ? "Nhận Ebook miễn phí" : "Nhận ngay"}
      </button>
    </form>
  );
}
