import Head from "next/head";
import { useRouter } from "next/router";
import { useEffect, useMemo, useState } from "react";
import { LiveChatWidget, EventHandlerPayload } from "@livechat/widget-react";
import { ScrollToTop } from "../btnHomeAndScrollTop/btnHomeAndScrollTop";
import Footer from "../footers/footer";
import Header from "../headers/header";
import Script from "next/script";
import PopupKm from "../popupKm/popupKm";

const Layout = (props: {
  children: JSX.Element;
  seoTitle: string;
  seoDescription: string;
  canonical: string;
  showBanner?: boolean;
  seoImage?: string;
  bannerCovid?: boolean;
  page?: number;
  totalPage?: number;
  point?: number;
  totalRate?: number;
  isArticel?: boolean;
  article;
}) => {
  const router = useRouter();

  const pageSeo = props.page > 1 && props.totalPage > 1 ? " - Page " + props.page + " of " + props.totalPage : "";

  const schemaPoint = {
    "@context": "https://schema.org/",
    "@type": "CreativeWorkSeries",
    name: props.seoTitle,
    aggregateRating: {
      "@type": "AggregateRating",
      ratingValue: props.totalRate ? (props.point ? String(props.point) : "5") : "5",
      bestRating: "5",
      ratingCount: props.totalRate ? String(props.totalRate) : "0",
    },
  };

  const schema1 = {
    "@context": "http://schema.org",
    "@type": "Organization",
    "@id": "https://www.sapo.vn/#organization",
    name: "Công ty cổ phần công nghệ Sapo",
    alternateName: "Sapo",
    legalName: "Sapo Technology JSC",
    url: "https://www.sapo.vn/",
    logo: "https://www.sapo.vn/Themes/Portal/Default/Styles_New/Images/logo/Sapo-logo.svg?v=201812260108",
    contactPoint: [
      {
        "@type": "ContactPoint",
        telephone: "+84 1900 6750",
        contactType: "sales",
        areaServed: "VN",
      },
      {
        "@type": "ContactPoint",
        telephone: "+84 1900 6750",
        contactType: "customer support",
        areaServed: "VN",
        email: "support@sapo.vn",
      },
    ],
    sameAs: [
      "https://www.youtube.com/channel/UCXOMQd_gKgELyY_fhmPjI4w",
      "https://www.crunchbase.com/organization/dkt-technology",
      "https://vi-vn.facebook.com/sapo.vn/",
      "https://dkt.com.vn/",
      "https://www.linkedin.com/in/sapo-vn",
      "https://twitter.com/Sapo46757947 ",
      "https://www.pinterest.com/sapovietnam/",
      "https://about.me/sapovn",
      "https://www.diigo.com/user/sapovn",
      "https://www.behance.net/Marketinge1c0",
      "https://soundcloud.com/user-103808877",
      "https://imgur.com/user/sapovn",
      "https://www.kongregate.com/accounts/sapovn",
      "https://moz.com/community/users/12106775",
    ],
  };

  const schema2 = {
    "@context": "http://schema.org",
    "@id": "https://www.sapo.vn/#website",
    "@type": "WebSite",
    url: "https://www.sapo.vn/",
    name: "Sapo",
  };
  const schema3 = {
    "@context": "http://schema.org",
    "@type": "LocalBusiness",
    image: {
      "@type": "ImageObject",
      "@id": "https://www.sapo.vn/#logo",
      url: "https://www.sapo.vn/Themes/Portal/Default/StylesV2/images/logo/Sapo-logo.svg",
      caption: "Sapo - Nền tảng quản lý và bán hàng đa kênh",
    },
    priceRange: "$$$",
    address: {
      "@type": "PostalAddress",
      streetAddress: "Tầng 6, Tòa nhà Ladeco, 266 Đội Cấn, Phường Liễu Giai",
      addressLocality: "Ba Đình, Hà Nội",
      addressRegion: "Việt Nam",
      addressCountry: "Hà Nội",
    },
    description:
      "Sapo là nền tảng quản lý và bán hàng đa kênh được sử dụng nhiều nhất Việt Nam với hơn 100,000 khách hàng. Giúp bạn bán hàng từ online đến cửa hàng và quản lý tập trung.",
    name: "Sapo",
    telephone: "19006750",
    geo: {
      "@type": "GeoCoordinates",
      latitude: "21.0363539",
      longitude: "105.813755",
    },
    hasMap: "https://www.google.com/maps?cid=11812001040537350031",
    openingHours: "Mo 08:00-17:30 Tu 08:00-17:30 We 08:00-17:30 Th 08:00-17:30 Fr 08:00-17:30 Sa 08:00-12:00",
  };
  const [liveChatSapo, setLiveChatSapo] = useState(null);
  const [scrollToTop, setScrollToTop] = useState(null);
  useEffect(() => {
    setTimeout(() => {
      setLiveChatSapo(<LiveChatWidget license="12284820" />);
      setScrollToTop(<ScrollToTop />);
    }, 5000);
  }, []);

  const orUrl = router.asPath === "/" ? "" : router.asPath;
  const arrTag = props?.article?.articleTagsMappings;
  const findTagNoIndex = useMemo(() => {
    return arrTag?.find((obj) => obj.tag === "noindex");
  }, [arrTag]);

  return (
    <>
      <Head>
        <meta httpEquiv="content-Type" content="text/html; charset=utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <meta name="theme-color" content="#42d086" />
        <link rel="icon" href="https://www.sapo.vn/favicon.ico" />

        {props.isArticel ? (
          <>{findTagNoIndex?.id ? <meta name="robots" content="noindex,nofollow" /> : <meta name="robots" content="index,follow" />}</>
        ) : (
          <meta name="theme-article-check" content="false" />
        )}

        <title>{props.seoTitle ? `${props.seoTitle}${pageSeo}` : "Sapo blog"}</title>
        {props.seoTitle ? (
          <>
            <meta name="title" content={`${props.seoTitle}${pageSeo}`} />
            <meta property="og:title" content={`${props.seoTitle}${pageSeo}`} />
          </>
        ) : (
          <meta property="og:site_name" content={`Blog chia sẻ kinh nghiệm kinh doanh online hiệu quả nhất${pageSeo}`} />
        )}
        {props.seoDescription ? (
          <>
            <meta name="description" content={`${props.seoDescription}${pageSeo}`} />
            <meta property="og:description" content={`${props.seoDescription}${pageSeo}`} />
          </>
        ) : (
          <>
            <meta
              name="description"
              content={`Bạn đang kinh doanh online và muốn tìm cách bán hàng online hiệu quả? Đọc ngay Blog Sapo để cập nhật kiến thức kinh doanh online hiệu quả và cách mở cửa hàng kinh doanh thiết thực nhất${pageSeo}`}
            />
            <meta
              property="og:description"
              content={`Bạn đang kinh doanh online và muốn tìm cách bán hàng online hiệu quả? Đọc ngay Blog Sapo để cập nhật kiến thức kinh doanh online hiệu quả và cách mở cửa hàng kinh doanh thiết thực nhất${pageSeo}`}
            />
          </>
        )}
        <meta property="og:image" content={props.seoImage || "https://blog.dktcdn.net/files/thumbnail-blog.png"} />

        <link
          rel="canonical"
          href={`${process.env.NEXT_PUBLIC_BASE_URL + process.env.NEXT_PUBLIC_BASE_PATH}${props?.canonical ? "/" + props.canonical : ""}`}
        />
        <meta property="og:locale" content="vi_VN" />
        <meta property="og:type" content="website" />

        <meta property="og:url" content={process.env.NEXT_PUBLIC_BASE_URL + process.env.NEXT_PUBLIC_BASE_PATH + orUrl} />
        <link
          href="https://www.sapo.vn/Themes/Portal/Default/StylesV2/dist/popup-register-new.min.css?v=637624723248034074"
          rel="preload"
          as="style"
          type="text/css"
        />
        <link
          rel="preload"
          href="https://www.sapo.vn/Themes/Portal/Default/StylesV2/lib/tom-select/tom-select.complete.js?v=637624723249754077"
          as="script"
        />
        <link rel="preload" href="https://www.sapo.vn/Themes/Portal/Default/Scripts/dist/popup-trial-v2.min.js?v=637624908114879348" as="script" />
        <link rel="preload" href={process.env.NEXT_PUBLIC_BASE_PATH + "/form-registration.js"} as="script" />
        <link rel="preload" href="/blog/images/logo/sapo-logo.svg" as="image" />
        {props.isArticel && props.totalRate && (
          <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(schemaPoint) }} />
        )}
        <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(schema1) }} />
        <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(schema2) }} />
        <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(schema3) }} />

        <meta property="fb:app_id" content="232837080820948" />
      </Head>
      <link href="https://www.sapo.vn/Themes/Portal/Default/StylesV2/dist/popup-register-new.min.css?v=637624723248034074" rel="stylesheet" />
      <Script id="scriptAddloadEvent">
      {`function addLoadEvent(e) { if (document.readyState === "complete") { e() } else { var t = window.onload; if (typeof window.onload != "function") { window.onload = e } else { window.onload = function () { if (t) { t() } e() } } } }`}
      </Script>
      <Script src="https://www.sapo.vn/variable-trial.js" async type="text/javascript"></Script>
      <Script src="https://www.sapo.vn/Themes/Portal/Default/Scripts/dist/popup-trial-v2.min.js?v=637624908114879349" async></Script>
      <Script src="https://www.sapo.vn/Themes/Portal/Default/StylesV2/lib/tom-select/tom-select.complete.js?v=63762472324975407710" async></Script>
      <Script src={process.env.NEXT_PUBLIC_BASE_PATH + "/form-registration.js"} async />
      <Script async src="//sapo.api.useinsider.com/ins.js?id=10007403"></Script>
      <Header showBanner={props.showBanner} bannerCovid={props.bannerCovid} />
      <main>{props.children}</main>
      <Footer />
      {liveChatSapo}
      {scrollToTop}
    </>
  );
};

Layout.defaultProps = {
  seoTitle: null,
  seoDescription: null,
  canonical: null,
};

export default Layout;
