import moment from "moment";
import { ICategory } from "./../../model/categories";
export const dataMenuFake: ICategory[] = [
  {
    id: 4,
    title: "Kinh nghiệm kinh doanh",
    alias: "kinh-nghiem-kinh-doanh",
    description:
      "Bạn đang có một ý tưởng kinh doanh tuyệt với và muốn bắt đầu kinh doanh online, hay kinh doanh nhỏ. Tuy nhiên, bản thân mình lại chưa hiểu hiểu hết về thị trường mới mẻ này và không biết nên bắt đầu kinh doanh từ đâu, và làm như thế nào? Đừng lo lắng, Sapo sẽ hướng dẫn cho bạn chi tiết từng bước kinh doanh, từ khảo sát thị trường, nghiên cứu đối thủ cạnh tranh, cho đến làm sao để hiểu hơn về khách hàng, cách kế hoạch kinh doanh, và bí quyết có được nguồn hàng kinh doanh chất lượng.",
    parent_id: null,
    image: null,
    depth: 0,
    lineage: "/",
    has_child: true,
    published_at: null,
    seo_title: null,
    seo_description: null,
    created_by: null,
    created_at: moment(1626161070000).toDate(),
    modified_at: moment(1626161105000).toDate(),
    article_count: 0,
  },
  {
    id: 5,
    title: "Ý tưởng kinh doanh",
    alias: "kinh-nghiem-kinh-doanh/y-tuong-kinh-doanh-2021",
    description: null,
    parent_id: 4,
    image: null,
    depth: 1,
    lineage: "/4/",
    has_child: false,
    published_at: null,
    seo_title: null,
    seo_description: null,
    created_by: null,
    created_at: moment(1626161105000).toDate(),
    modified_at: moment(1626677568000).toDate(),
    article_count: 0,
  },
  {
    id: 7,
    title: "Xu hướng thị trường",
    alias: "kinh-nghiem-kinh-doanh/xu-huong-thi-truong",
    description: null,
    parent_id: 4,
    image: null,
    depth: 1,
    lineage: "/4/",
    has_child: false,
    published_at: null,
    seo_title: null,
    seo_description: null,
    created_by: null,
    created_at: moment(1626164878000).toDate(),
    modified_at: moment(1626505285000).toDate(),
    article_count: 0,
  },
  {
    id: 8,
    title: "Kế hoạch kinh doanh",
    alias: "kinh-nghiem-kinh-doanh/ke-hoach-kinh-doanh",
    description: null,
    parent_id: 4,
    image: null,
    depth: 1,
    lineage: "/4/",
    has_child: false,
    published_at: null,
    seo_title: null,
    seo_description: null,
    created_by: null,
    created_at: moment(1626165296000).toDate(),
    modified_at: moment(1626505298000).toDate(),
    article_count: 0,
  },
  {
    id: 9,
    title: "Nguồn hàng kinh doanh",
    alias: "kinh-nghiem-kinh-doanh/nguon-hang-kinh-doanh",
    description: null,
    parent_id: 4,
    image: null,
    depth: 1,
    lineage: "/4/",
    has_child: false,
    published_at: null,
    seo_title: null,
    seo_description: null,
    created_by: null,
    created_at: moment(1626165368000).toDate(),
    modified_at: moment(1626505305000).toDate(),
    article_count: 0,
  },
  {
    id: 10,
    title: "Câu chuyện thành công",
    alias: "kinh-nghiem-kinh-doanh/cau-chuyen-thanh-cong",
    description: null,
    parent_id: 4,
    image: null,
    depth: 1,
    lineage: "/4/",
    has_child: false,
    published_at: null,
    seo_title: null,
    seo_description: null,
    created_by: null,
    created_at: moment(1626165687000).toDate(),
    modified_at: moment(1626165687000).toDate(),
    article_count: 0,
  },
  // {
  //   id: 11,
  //   title: "Tra cứu ngành hàng",
  //   alias: "tra-cuu",
  //   description: null,
  //   parent_id: null,
  //   image: null,
  //   depth: 0,
  //   lineage: "/",
  //   has_child: false,
  //   published_at: null,
  //   seo_title: null,
  //   seo_description: null,
  //   created_by: null,
  //   created_at: moment(1626165699000,
  //   modified_at: moment(1626165699000,
  //   article_count: 0
  // },
  {
    id: 12,
    title: "Kênh bán hàng",
    alias: "kenh-ban-hang",
    description: null,
    parent_id: null,
    image: null,
    depth: 0,
    lineage: "/",
    has_child: true,
    published_at: null,
    seo_title: null,
    seo_description: null,
    created_by: null,
    created_at: moment(1626165720000).toDate(),
    modified_at: moment(1626165742000).toDate(),
    article_count: 0,
  },
  {
    id: 13,
    title: "Cách bán hàng trên website",
    alias: "kenh-ban-hang/cach-ban-hang-tren-website",
    description: null,
    parent_id: 12,
    image: null,
    depth: 1,
    lineage: "/12/",
    has_child: false,
    published_at: null,
    seo_title: null,
    seo_description: null,
    created_by: null,
    created_at: moment(1626165742000).toDate(),
    modified_at: moment(1626165742000).toDate(),
    article_count: 0,
  },
  {
    id: 14,
    title: "Cách bán tại cửa hàng",
    alias: "kenh-ban-hang/cach-ban-tai-cua-hang",
    description: null,
    parent_id: 12,
    image: null,
    depth: 1,
    lineage: "/12/",
    has_child: false,
    published_at: null,
    seo_title: null,
    seo_description: null,
    created_by: null,
    created_at: moment(1626165754000).toDate(),
    modified_at: moment(1626165754000).toDate(),
    article_count: 0,
  },
  {
    id: 15,
    title: "Cách bán trên mạng xã hội",
    alias: "kenh-ban-hang/cach-ban-tren-mang-xa-hoi",
    description: null,
    parent_id: 12,
    image: null,
    depth: 1,
    lineage: "/12/",
    has_child: false,
    published_at: null,
    seo_title: null,
    seo_description: null,
    created_by: null,
    created_at: moment(1626169422000).toDate(),
    modified_at: moment(1626169422000).toDate(),
    article_count: 0,
  },
  {
    id: 16,
    title: "Cách bán trên sàn TMĐT",
    alias: "kenh-ban-hang/cach-ban-tren-san-tmdt",
    description: null,
    parent_id: 12,
    image: null,
    depth: 1,
    lineage: "/12/",
    has_child: false,
    published_at: null,
    seo_title: null,
    seo_description: null,
    created_by: null,
    created_at: moment(1626169445000).toDate(),
    modified_at: moment(1626169445000).toDate(),
    article_count: 0,
  },
  {
    id: 17,
    title: "Chiến lược Omnichannel",
    alias: "kenh-ban-hang/chien-luoc-omnichannel",
    description: null,
    parent_id: 12,
    image: null,
    depth: 1,
    lineage: "/12/",
    has_child: false,
    published_at: null,
    seo_title: null,
    seo_description: null,
    created_by: null,
    created_at: moment(1626169456000).toDate(),
    modified_at: moment(1626169456000).toDate(),
    article_count: 0,
  },
  {
    id: 18,
    title: "Quản lý bán hàng",
    alias: "quan-ly-ban-hang",
    description: null,
    parent_id: null,
    image: null,
    depth: 0,
    lineage: "/",
    has_child: true,
    published_at: null,
    seo_title: null,
    seo_description: null,
    created_by: null,
    created_at: moment(1626169471000).toDate(),
    modified_at: moment(1626169483000).toDate(),
    article_count: 0,
  },
  {
    id: 19,
    title: "Quản lý nhà hàng - cafe",
    alias: "quan-ly-ban-hang/quan-ly-nha-hang",
    description: null,
    parent_id: 18,
    image: null,
    depth: 1,
    lineage: "/18/",
    has_child: false,
    published_at: null,
    seo_title: null,
    seo_description: null,
    created_by: null,
    created_at: moment(1626169483000).toDate(),
    modified_at: moment(1626169483000).toDate(),
    article_count: 0,
  },
  {
    id: 20,
    title: "Quản lý tồn kho",
    alias: "quan-ly-ban-hang/quan-ly-ton-kho",
    description: null,
    parent_id: 18,
    image: null,
    depth: 1,
    lineage: "/18/",
    has_child: false,
    published_at: null,
    seo_title: null,
    seo_description: null,
    created_by: null,
    created_at: moment(1626170312000).toDate(),
    modified_at: moment(1626170312000).toDate(),
    article_count: 0,
  },
  {
    id: 21,
    title: "Quản lý khách hàng",
    alias: "quan-ly-ban-hang/quan-ly-khach-hang",
    description: null,
    parent_id: 18,
    image: null,
    depth: 1,
    lineage: "/18/",
    has_child: false,
    published_at: null,
    seo_title: null,
    seo_description: null,
    created_by: null,
    created_at: moment(1626170323000).toDate(),
    modified_at: moment(1626170323000).toDate(),
    article_count: 0,
  },
  {
    id: 22,
    title: "Quản lý nhân sự",
    alias: "quan-ly-ban-hang/quan-ly-nhan-su",
    description: null,
    parent_id: 18,
    image: null,
    depth: 1,
    lineage: "/18/",
    has_child: false,
    published_at: null,
    seo_title: null,
    seo_description: null,
    created_by: null,
    created_at: moment(1626170335000).toDate(),
    modified_at: moment(1626170335000).toDate(),
    article_count: 0,
  },
  {
    id: 23,
    title: "Báo cáo kinh doanh",
    alias: "bao-cao-kinh-doanh",
    description: null,
    parent_id: 18,
    image: null,
    depth: 1,
    lineage: "/18/",
    has_child: false,
    published_at: null,
    seo_title: null,
    seo_description: null,
    created_by: null,
    created_at: moment(1626170347000).toDate(),
    modified_at: moment(1626170347000).toDate(),
    article_count: 0,
  },
  {
    id: 24,
    title: "Quản lý đơn hàng",
    alias: "quan-ly-ban-hang/quan-ly-don-hang",
    description: null,
    parent_id: 18,
    image: null,
    depth: 1,
    lineage: "/18/",
    has_child: false,
    published_at: null,
    seo_title: null,
    seo_description: null,
    created_by: null,
    created_at: moment(1626170355000).toDate(),
    modified_at: moment(1626170355000).toDate(),
    article_count: 0,
  },
  {
    id: 25,
    title: "Vận chuyển đơn hàng",
    alias: "quan-ly-ban-hang/van-chuyen-don-hang",
    description: null,
    parent_id: 18,
    image: null,
    depth: 1,
    lineage: "/18/",
    has_child: false,
    published_at: null,
    seo_title: null,
    seo_description: null,
    created_by: null,
    created_at: moment(1626170460000).toDate(),
    modified_at: moment(1626170460000).toDate(),
    article_count: 0,
  },
  
  {
    id: 27,
    title: "Tin & Sự kiện Sapo",
    alias: "tin-su-kien-sapo",
    description: null,
    parent_id: null,
    image: null,
    depth: 0,
    lineage: "/",
    has_child: true,
    published_at: null,
    seo_title: null,
    seo_description: null,
    created_by: null,
    created_at: moment(1626170489000).toDate(),
    modified_at: moment(1626236756000).toDate(),
    article_count: 0,
  },
  {
    id: 28,
    title: "Kế toán - Pháp lý",
    alias: "kinh-nghiem-kinh-doanh/ke-toan-phap-ly",
    description: null,
    parent_id: 4,
    image: null,
    depth: 1,
    lineage: "/4/",
    has_child: false,
    published_at: null,
    seo_title: null,
    seo_description: null,
    created_by: null,
    created_at: moment(1626170591000).toDate(),
    modified_at: moment(1626170591000).toDate(),
    article_count: 0,
  },
  // {
  //   id: 29,
  //   title: "Hỏi đáp",
  //   alias: "hoi-dap",
  //   description: null,
  //   parent_id: 4,
  //   image: null,
  //   depth: 1,
  //   lineage: "/4/",
  //   has_child: false,
  //   published_at: null,
  //   seo_title: null,
  //   seo_description: null,
  //   created_by: null,
  //   created_at: moment(1626170607000,
  //   modified_at: moment(1626170607000,
  //   article_count: 0
  // },
  {
    id: 30,
    title: "Quản lý dòng tiền",
    alias: "quan-ly-ban-hang/quan-ly-nguon-von",
    description: null,
    parent_id: 18,
    image: null,
    depth: 1,
    lineage: "/18/",
    has_child: false,
    published_at: null,
    seo_title: null,
    seo_description: null,
    created_by: null,
    created_at: moment(1626236704000).toDate(),
    modified_at: moment(1626236704000).toDate(),
    article_count: 0,
  },
  {
    id: 31,
    title: "Sapo Updates",
    alias: "tin-su-kien-sapo/sapo-update",
    description: null,
    parent_id: 27,
    image: null,
    depth: 1,
    lineage: "/27/",
    has_child: false,
    published_at: null,
    seo_title: null,
    seo_description: null,
    created_by: null,
    created_at: moment(1626236756000).toDate(),
    modified_at: moment(1626236806000).toDate(),
    article_count: 0,
  },
  {
    id: 32,
    title: "Sự kiện Sapo",
    alias: "tin-su-kien-sapo/su-kien-sapo",
    description: null,
    parent_id: 27,
    image: null,
    depth: 1,
    lineage: "/27/",
    has_child: false,
    published_at: null,
    seo_title: null,
    seo_description: null,
    created_by: null,
    created_at: moment(1626236827000).toDate(),
    modified_at: moment(1626236827000).toDate(),
    article_count: 0,
  },
  {
    id: 33,
    title: "Tin Sapo",
    alias: "tin-su-kien-sapo/tin-sapo",
    description: null,
    parent_id: 27,
    image: null,
    depth: 1,
    lineage: "/27/",
    has_child: false,
    published_at: null,
    seo_title: null,
    seo_description: null,
    created_by: null,
    created_at: moment(1626236852000).toDate(),
    modified_at: moment(1626236852000).toDate(),
    article_count: 0,
  },
  {
    id: 34,
    title: "Sapo Webinar",
    alias: "tin-su-kien-sapo/sapo-webinar",
    description: null,
    parent_id: 27,
    image: null,
    depth: 1,
    lineage: "/27/",
    has_child: false,
    published_at: null,
    seo_title: null,
    seo_description: null,
    created_by: null,
    created_at: moment(1626236866000).toDate(),
    modified_at: moment(1626236866000).toDate(),
    article_count: 0,
  },
  {
    id: 35,
    title: "Sapo Affiliate",
    alias: "tin-su-kien-sapo/sapo-affiliate",
    description: null,
    parent_id: 27,
    image: null,
    depth: 1,
    lineage: "/27/",
    has_child: false,
    published_at: null,
    seo_title: null,
    seo_description: null,
    created_by: null,
    created_at: moment(1626236875000).toDate(),
    modified_at: moment(1626236875000).toDate(),
    article_count: 0,
  },
  {
    id: 36,
    title: "Sapo Web Theme",
    alias: "tin-su-kien-sapo/sapo-web-theme",
    description: null,
    parent_id: 27,
    image: null,
    depth: 1,
    lineage: "/27/",
    has_child: false,
    published_at: null,
    seo_title: null,
    seo_description: null,
    created_by: null,
    created_at: moment(1626236888000).toDate(),
    modified_at: moment(1626236888000).toDate(),
    article_count: 0,
  },
  {
    id: 39,
    title: "PR Marketing",
    alias: "kinh-nghiem-kinh-doanh/pr-marketing",
    description: null,
    parent_id: 4,
    image: null,
    depth: 1,
    lineage: "/4/",
    has_child: false,
    published_at: null,
    seo_title: null,
    seo_description: null,
    created_by: null,
    created_at: moment(1626494388000).toDate(),
    modified_at: moment(1626494388000).toDate(),
    article_count: 0,
  },
  {
    id: 40,
    title: "Kinh doanh nhà hàng - cafe",
    alias: "kinh-nghiem-kinh-doanh/kinh-doanh-nha-hang",
    description: null,
    parent_id: 4,
    image: null,
    depth: 1,
    lineage: "/4/",
    has_child: false,
    published_at: null,
    seo_title: null,
    seo_description: null,
    created_by: null,
    created_at: moment(1626685391000).toDate(),
    modified_at: moment(1626685391000).toDate(),
    article_count: 0,
  },
  // {
  //   id: 41,
  //   title: "Điểm tin Covid",
  //   alias: "kinh-nghiem-kinh-doanh/chuyen-dong-kinh-doanh-mua-covid",
  //   description: null,
  //   parent_id: null,
  //   image: null,
  //   depth: 0,
  //   lineage: "/",
  //   has_child: false,
  //   published_at: null,
  //   seo_title: null,
  //   seo_description: null,
  //   created_by: null,
  //   created_at: moment(1626685391000,
  //   modified_at: moment(1626685391000,
  //   article_count: 0
  // },
  {
    id: 41,
    title: "Tra cứu",
    alias: "tra-cuu",
    description: null,
    parent_id: null,
    image: null,
    depth: 0,
    lineage: "/",
    has_child: false,
    published_at: null,
    seo_title: null,
    seo_description: null,
    created_by: null,
    created_at: moment(1626685391000).toDate(),
    modified_at: moment(1626685391000).toDate(),
    article_count: 0,
  },
  {
    id: 42,
    title: "Hỏi đáp",
    alias: "kinh-nghiem-kinh-doanh/hoi-dap",
    description: null,
    parent_id: 4,
    image: null,
    depth: 0,
    lineage: "/",
    has_child: false,
    published_at: null,
    seo_title: null,
    seo_description: null,
    created_by: null,
    created_at: moment(1626685391000).toDate(),
    modified_at: moment(1626685391000).toDate(),
    article_count: 0,
  },
  {
    id: 26,
    title: "Ebook miễn phí",
    alias: "ebook",
    description: null,
    parent_id: 4,
    image: null,
    depth: 0,
    lineage: "/",
    has_child: false,
    published_at: null,
    seo_title: null,
    seo_description: null,
    created_by: null,
    created_at: moment(1626170470000).toDate(),
    modified_at: moment(1626678301000).toDate(),
    article_count: 0
  }
];
