import React from "react";
const FooterAddress = () => {
  return (
    <div>
      <div id="myModal-ebook" className="sapo-modal-register fade" role="dialog"></div>
      <div id="modalRegisterSuccess-ebook" className="sapo-modal-register sapo-modal-register-success fade" role="dialog"></div>
      <div id="myModal-tuvan-finance" className="sapo-modal-register fade" role="dialog"></div>
      <div id="modalRegisterSuccess-finance" className="sapo-modal-register modal-register modal-register-success-default fade" role="dialog"></div>
      <div id="myModal-tuvan-express" className="sapo-modal-register fade" role="dialog"></div>
      <div id="modalRegisterSuccess-express" className="sapo-modal-register sapo-modal-register-success fade" role="dialog"></div>
      <div id="myModal-tuvan-sapopay" className="sapo-modal-register fade" role="dialog"></div>
      <div id="modalRegisterSuccess-sapopay" className="sapo-modal-register modal-register modal-register-success-default fade" role="dialog"></div>
      <div id="myModal-tuvan-unsecuredloan" className="sapo-modal-register fade" role="dialog"></div>
      <div
        id="modalRegisterSuccess-unsecuredloan"
        className="sapo-modal-register modal-register modal-register-success-default fade oo"
        role="dialog"
      ></div>
    </div>
  );
};

export default FooterAddress;
