import Image from "next/legacy/image";
import Link from "next/link";
interface INavMenu {
  title: string;
  image: string;
  link?: string;
  width: number;
  height: number;
  subMenu?: ISubMenu[];
}
export interface ISubMenu {
  title: string;
  link: string;
}
const NavMenu = (props: INavMenu) => {
  const { title, link, image, subMenu, width, height } = props;
  return (
    <>
      <Link href={link} aria-label={title === "" ? "Trang chủ" : null}>
        {title === "" && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="28px"
            height="24px"
            viewBox="0 0 20 17"
            version="1.1"
          >
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g transform="translate(-816.000000, -289.000000)">
                <g transform="translate(100.000000, 100.000000)">
                  <g transform="translate(714.000000, 186.000000)">
                    <g transform="translate(0.000000, 0.000000)">
                      <polygon points="0 0 24 0 24 24 0 24" />
                      <path
                        d="M10,19 L10,14 L14,14 L14,19 C14,19.55 14.45,20 15,20 L18,20 C18.55,20 19,19.55 19,19 L19,12 L20.7,12 C21.16,12 21.38,11.43 21.03,11.13 L12.67,3.6 C12.29,3.26 11.71,3.26 11.33,3.6 L2.97,11.13 C2.63,11.43 2.84,12 3.3,12 L5,12 L5,19 C5,19.55 5.45,20 6,20 L9,20 C9.55,20 10,19.55 10,19 Z"
                        fill="#fff"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        )}
        {title && <span>{title}</span>}
      </Link>
      <ul className="sub-menu">
        {subMenu?.length > 0 &&
          subMenu.map((item, index) => (
            <li key={index}>
              <Link href={item.link} title={item.title}>
                {item.title}
              </Link>
              {/* <a href={`${process.env.NEXT_PUBLIC_BASE_URL + process.env.NEXT_PUBLIC_BASE_PATH}${item.link}`} title={item.title}>{item.title}</a> */}
            </li>
          ))}
      </ul>
    </>
  );
};

export default NavMenu;
