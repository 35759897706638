import styles from "./header.module.scss";
import classnames from "classnames";
import { useRef, useState } from "react";
import { useRouter } from "next/router";
import { useOnClickOutsideModal } from "../../util/functionGlobal";
import NavMenu, { ISubMenu } from "./nav-menu";

const NavPc = () => {
  const router = useRouter();
  const [keyword, setKeyWord] = useState<string>("");

  const [clickInput, setClickInput] = useState<boolean>(false);
  const ref = useRef();
  useOnClickOutsideModal(ref, () => setClickInput(false), "show-search");

  const handleChange = (e) => {
    setKeyWord(e.target.value);
  };
  const clickSearch = (e) => {
    setClickInput(!clickInput);
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
    if (keyword.trim()) {
      router.push(`/?query=` + keyword.trim());
    }
    setClickInput(false);
  };

  const knKinhDoanhData: ISubMenu[] = [
    {
      link: "/kinh-nghiem-kinh-doanh/y-tuong-kinh-doanh-2021/",
      title: "Ý tưởng kinh doanh",
    },
    {
      link: "/kinh-nghiem-kinh-doanh/xu-huong-thi-truong/",
      title: "Xu hướng thị trường",
    },
    {
      link: "/kinh-nghiem-kinh-doanh/ke-hoach-kinh-doanh/",
      title: "Kế hoạch kinh doanh",
    },
    {
      link: "/kinh-nghiem-kinh-doanh/nguon-hang-kinh-doanh/",
      title: "Nguồn hàng kinh doanh",
    },
    {
      link: "/kinh-nghiem-kinh-doanh/cau-chuyen-thanh-cong/",
      title: "Câu chuyện thành công",
    },
    {
      link: "/kinh-nghiem-kinh-doanh/ke-toan-phap-ly/",
      title: "Kế toán-pháp lý",
    },
    {
      link: "/kinh-nghiem-kinh-doanh/pr-marketing/",
      title: "PR Marketing",
    },
    {
      link: "/kinh-nghiem-kinh-doanh/kinh-doanh-nha-hang/",
      title: "Kinh doanh nhà hàng - cafe",
    },

    {
      link: "/kinh-nghiem-kinh-doanh/hoi-dap/",
      title: "Hỏi đáp",
    },
    {
      link: "/ebook",
      title: "Ebook miễn phí",
    },
  ];
  const kenhBanHangData: ISubMenu[] = [
    {
      link: "/kenh-ban-hang/cach-ban-hang-tren-website/",
      title: "Cách bán hàng trên website",
    },
    {
      link: "/kenh-ban-hang/cach-ban-tai-cua-hang/",
      title: "Cách bán tại cửa hàng",
    },
    {
      link: "/kenh-ban-hang/cach-ban-tren-mang-xa-hoi/",
      title: "Cách bán trên mạng xã hội",
    },
    {
      link: "/kenh-ban-hang/cach-ban-tren-san-tmdt/",
      title: "Cách bán trên sàn TMĐT",
    },
    {
      link: "/kenh-ban-hang/chien-luoc-omnichannel/",
      title: "Chiến lược Omnichannel",
    },
  ];
  const qlBanHangData: ISubMenu[] = [
    {
      link: "/quan-ly-ban-hang/quan-ly-nha-hang/",
      title: "Quản lý nhà hàng - cafe",
    },
    {
      link: "/quan-ly-ban-hang/quan-ly-ton-kho/",
      title: "Quản lý tồn kho",
    },
    {
      link: "/quan-ly-ban-hang/quan-ly-khach-hang/",
      title: "Quản lý khách hàng",
    },
    {
      link: "/quan-ly-ban-hang/quan-ly-nhan-su/",
      title: "Quản lý nhân sự",
    },
    {
      link: "/quan-ly-ban-hang/quan-ly-nguon-von/",
      title: "Quản lý dòng tiền",
    },
    {
      link: "/quan-ly-ban-hang/bao-cao-kinh-doanh/",
      title: "Báo cáo kinh doanh",
    },
    {
      link: "/quan-ly-ban-hang/quan-ly-don-hang/",
      title: "Quản lý đơn hàng",
    },
    {
      link: "/quan-ly-ban-hang/van-chuyen-don-hang/",
      title: "Vận chuyển đơn hàng",
    },
  ];
  const tinTucData: ISubMenu[] = [
    {
      link: "/tin-su-kien-sapo/sapo-update/",
      title: "Sapo Updates",
    },
    {
      link: "/tin-su-kien-sapo/su-kien-sapo/",
      title: "Sự kiện Sapo",
    },
    {
      link: "/tin-su-kien-sapo/tin-sapo/",
      title: "Tin Sapo",
    },
    {
      link: "/tin-su-kien-sapo/sapo-webinar/",
      title: "Sapo Webinar",
    },
    {
      link: "/tin-su-kien-sapo/sapo-affiliate/",
      title: "Sapo Affiliate",
    },
    {
      link: "/tin-su-kien-sapo/sapo-web-theme/",
      title: "Sapo Web Theme",
    },
  ];
  return (
    <div className={classnames(styles["blog-menu"], "d-lg-block", "d-none")}>
      <div className="container d-lg-flex justify-content-between">
        <ul>
          <li className={styles["icon-menu"]}>
            <NavMenu title="" link="/" image={process.env.NEXT_PUBLIC_BASE_PATH + "/images/icon-home.png"} width={21} height={21} />
          </li>
          <li>
            <NavMenu
              title="Kinh nghiệm kinh doanh"
              link="/kinh-nghiem-kinh-doanh/"
              image={process.env.NEXT_PUBLIC_BASE_PATH + "/images/icon-kinh-nghiem-kinh-doanh.png"}
              subMenu={knKinhDoanhData}
              width={23}
              height={21}
            />
          </li>
          {/* <li>
            <NavMenu title="Tra c???u ng??nh h??ng" link="/tra-cuu" image={process.env.NEXT_PUBLIC_BASE_PATH + "/images/icon-tra-cuu.png"}
              width={19} height={22} />
          </li> */}
          <li>
            <NavMenu
              title="Kênh bán hàng"
              link="/kenh-ban-hang"
              image={process.env.NEXT_PUBLIC_BASE_PATH + "/images/icon-kenh-ban-hang.png"}
              subMenu={kenhBanHangData}
              width={19}
              height={22}
            />
          </li>
          <li>
            <NavMenu
              title="Quản lý bán hàng"
              link="/quan-ly-ban-hang/"
              image={process.env.NEXT_PUBLIC_BASE_PATH + "/images/icon-kinh-nghiem-kinh-doanh.png"}
              subMenu={qlBanHangData}
              width={23}
              height={21}
            />
          </li>

          <li>
            <NavMenu
              title="Công nghệ"
              link="/cong-nghe"
              image={process.env.NEXT_PUBLIC_BASE_PATH + "/images/icon-ebook-mien-phi.png"}
              width={19}
              height={18}
            />
          </li>
          <li>
            <NavMenu
              title="Tin & Sự kiện Sapo"
              link="/tin-su-kien-sapo"
              image={process.env.NEXT_PUBLIC_BASE_PATH + "/images/icon-ebook-mien-phi.png"}
              subMenu={tinTucData}
              width={19}
              height={18}
            />
          </li>
          {/* <li>
            <NavMenu
              title="Điểm tin Covid"
              link="/kinh-nghiem-kinh-doanh/chuyen-dong-kinh-doanh-mua-covid"
              image={process.env.NEXT_PUBLIC_BASE_PATH + "/images/icon-ebook-mien-phi.png"}
              width={19}
              height={18}
            />
          </li> */}
          <li>
            <NavMenu
              title="Tra cứu"
              link="/tra-cuu"
              image={process.env.NEXT_PUBLIC_BASE_PATH + "/images/icon-ebook-mien-phi.png"}
              width={19}
              height={18}
            />
          </li>
        </ul>
        <div className={styles["search-form"]}>
          <label
            htmlFor={styles["check-search"]}
            className={classnames(styles["btn-search"], styles[clickInput ? "show-search" : ""])}
            onClick={() => clickSearch}
          >
            <svg
              className={styles["icon-search"]}
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              version="1.1"
              id="Capa_1"
              x="0px"
              y="0px"
              viewBox="0 0 511.999 511.999"
              xmlSpace="preserve"
            >
              <g>
                <path d="M508.874,478.708L360.142,329.976c28.21-34.827,45.191-79.103,45.191-127.309c0-111.75-90.917-202.667-202.667-202.667    S0,90.917,0,202.667s90.917,202.667,202.667,202.667c48.206,0,92.482-16.982,127.309-45.191l148.732,148.732    c4.167,4.165,10.919,4.165,15.086,0l15.081-15.082C513.04,489.627,513.04,482.873,508.874,478.708z M202.667,362.667    c-88.229,0-160-71.771-160-160s71.771-160,160-160s160,71.771,160,160S290.896,362.667,202.667,362.667z" />
              </g>
            </svg>
            <svg
              className={styles["icon-close"]}
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 512.001 512.001"
              xmlSpace="preserve"
            >
              <g>
                <path d="M284.286,256.002L506.143,34.144c7.811-7.811,7.811-20.475,0-28.285c-7.811-7.81-20.475-7.811-28.285,0L256,227.717 L34.143,5.859c-7.811-7.811-20.475-7.811-28.285,0c-7.81,7.811-7.811,20.475,0,28.285l221.857,221.857L5.858,477.859 c-7.811,7.811-7.811,20.475,0,28.285c3.905,3.905,9.024,5.857,14.143,5.857c5.119,0,10.237-1.952,14.143-5.857L256,284.287 l221.857,221.857c3.905,3.905,9.024,5.857,14.143,5.857s10.237-1.952,14.143-5.857c7.811-7.811,7.811-20.475,0-28.285 L284.286,256.002z" />
              </g>
            </svg>
          </label>
          <input id={styles["check-search"]} type="checkbox" onChange={clickSearch} checked={clickInput} />
          <form ref={ref} id={classnames({ [styles.searchform]: clickInput })} action="" onSubmit={onFormSubmit}>
            {clickInput && (
              <input
                type="text"
                id="input-search"
                name="input-search"
                placeholder="Nhập từ khóa ..."
                onChange={handleChange}
                value={keyword}
                autoFocus
              />
            )}
            <button type="submit">
              <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" viewBox="0 0 129 129">
                <g>
                  <path d="M51.6,96.7c11,0,21-3.9,28.8-10.5l35,35c0.8,0.8,1.8,1.2,2.9,1.2s2.1-0.4,2.9-1.2c1.6-1.6,1.6-4.2,0-5.8l-35-35   c6.5-7.8,10.5-17.9,10.5-28.8c0-24.9-20.2-45.1-45.1-45.1C26.8,6.5,6.5,26.8,6.5,51.6C6.5,76.5,26.8,96.7,51.6,96.7z M51.6,14.7   c20.4,0,36.9,16.6,36.9,36.9C88.5,72,72,88.5,51.6,88.5c-20.4,0-36.9-16.6-36.9-36.9C14.7,31.3,31.3,14.7,51.6,14.7z" />
                </g>
              </svg>
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default NavPc;
