import classNames from "classnames";
import FooterAddress from "./footerAddress";
import FooterCopyright from "./footerCopyright";
import FooterMenu from "./footerMenu";
import FooterRegistration from "./footerRegistration";
import styles from "./footer.module.scss";
import QuickRegistration from "./quickRegistration";
import FooterSvg from "./footerSvg";

const Footer = () => {
  return (
    <footer>
      <QuickRegistration />
      <div id="footer" className={classNames(styles["footer"])}>
        <FooterMenu />
        <FooterAddress />
        <FooterCopyright />
      </div>
      <FooterRegistration />
      <FooterSvg/>
    </footer>
  );
};

export default Footer;
