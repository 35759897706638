import classNames from "classnames";
import React from "react";
import styles from "./footer.module.scss";
import Image from "next/legacy/image";
import Link from "next/link";
const FooterAddress = () => {
  const currentDate = new Date();
  const expirationDate1 = new Date('2023-07-01');
  return (
    <div className={styles["footer-address"]}>
      <div className="container">
        <div className="row">
          <div className={classNames(styles["col-w-20"], "col-md-4 d-lg-block d-none")}>
            <div className={styles.title}>Trợ giúp</div>
            <ul>
              <li>
                <a
                  href="//support.sapo.vn/?utm_campaign=cpn:support_sapo-plm:footer&utm_source=sapo.vn&utm_medium=referral&utm_content=fm:text_link-km:-sz:&utm_term=&campaign=footer_sapo"
                  rel="nofollow noopener noreferrer"
                  target="_blank"
                >
                  Trung tâm trợ giúp
                </a>
              </li>
              <li>
                <a
                  href="//support.sapo.vn/cac-hinh-thuc-thanh-toan-khi-su-dung-dich-vu-cua-sapo?utm_campaign=cpn:hinh_thuc_thanh_toan-plm:footer&utm_source=sapo.vn&utm_medium=referral&utm_content=fm:text_link-km:-sz:&utm_term=&campaign=footer_sapo"
                  rel="nofollow noopener noreferrer"
                  target="_blank"
                >
                  Hình thức thanh toán
                </a>
              </li>
              <li>
                <a
                  href="//support.sapo.vn/huong-dan-dang-nhap-he-thong-sapo?utm_campaign=cpn:huong_dan_dang_nhap-plm:footer&utm_source=sapo.vn&utm_medium=referral&utm_content=fm:text_link-km:-sz:&utm_term=&campaign=footer_sapo"
                  rel="nofollow noopener noreferrer"
                  target="_blank"
                >
                  Hướng dẫn đăng nhập Sapo
                </a>
              </li>
              <li>
                <a
                  href="//support.sapo.vn/quy-dinh-su-dung?utm_campaign=cpn:quy_sinh_su_dung-plm:footer&utm_source=sapo.vn&utm_medium=referral&utm_content=fm:text_link-km:-sz:&utm_term=&campaign=footer_sapo"
                  rel="nofollow noopener noreferrer"
                  target="_blank"
                >
                  Điều khoản & Điều kiện sử dụng
                </a>
              </li>
              <li>
                <a
                  href="//support.sapo.vn/chinh-sach-bao-mat?utm_campaign=cpn:chinh_sach_bao_mat-plm:footer&utm_source=sapo.vn&utm_medium=referral&utm_content=fm:text_link-km:-sz:&utm_term=&campaign=footer_sapo"
                  rel="nofollow noopener noreferrer"
                  target="_blank"
                >
                  Chính sách bảo mật
                </a>
              </li>
              <li>
                <a
                  href="//www.sapo.vn/lien-he.html?utm_campaign=cpn:lien_he-plm:footer&utm_source=sapo.vn&utm_medium=internal&utm_content=fm:text_link-km:-sz:&utm_term=&campaign=footer_sapo"
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                >
                  Liên hệ
                </a>
              </li>
            </ul>
          </div>
          <div className={classNames(styles["col-w-20"], "col-md-4")}>
            <div className="row">
              <div className={classNames(styles["col-sm-6"], "col-12")}>
                <div className={styles.title}>Dịch vụ</div>
                <ul>
                  <li>
                    <a
                      href="//www.sapo.vn/Themes/Portal/Default/Contents/bang-gia-dich-vu-support-don-le-2021.pdf?v=6"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Dịch vụ support
                    </a>
                  </li>
                  <li>
                    <a href="//www.sapo.vn/dang-ky-ten-mien.html" target="_blank" rel="noopener noreferrer">
                      Đăng ký tên miền
                    </a>
                  </li>
                  <li>
                    <a href="//www.sapo.vn/dich-vu-email-doanh-nghiep.html" target="_blank" rel="noopener noreferrer">
                      Email doanh nghiệp
                    </a>
                  </li>
                </ul>
              </div>
              <div className={classNames(styles["col-sm-6"], "col-12")}>
                <div className={classNames(styles["title"], styles["shared-experience"])}>
                  <a href="https://shop.sapo.vn/" target="_blank" rel="nofollow noopener noreferrer">
                    Thiết bị bán hàng
                  </a>
                </div>
                <ul>
                  <li>
                    <a href="https://shop.sapo.vn/may-in-hoa-don-may-in-bill" target="_blank" rel="nofollow noopener noreferrer">
                      Máy in hóa đơn
                    </a>
                  </li>
                  <li>
                    <a href="https://shop.sapo.vn/may-in-ma-vach" target="_blank" rel="nofollow noopener noreferrer">
                      Máy in mã vạch
                    </a>
                  </li>
                  <li>
                    <a href="https://shop.sapo.vn/may-quet-ma-vach" target="_blank" rel="nofollow noopener noreferrer">
                      Máy quét mã vạch
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className={classNames(styles["col-w-60"], "col-md-8")}>
            <div className={styles.title}>Liên hệ</div>
            <ul className={styles.social}>
              <li className={classNames(styles["icon-facebook"])}>
                <a href="https://www.facebook.com/sapo.vn/" target="_blank" rel="noopener noreferrer" aria-label="Facebook">
                  <i className="fa fa-facebook-square"></i>
                </a>
              </li>
              <li className={classNames(styles["icon-youtube"])}>
                <a href="https://www.youtube.com/channel/UCXOMQd_gKgELyY_fhmPjI4w" target="_blank" rel="noopener noreferrer" aria-label="Youtube">
                  <i className="fa fa-youtube"></i>
                </a>
              </li>
              <li className={classNames(styles["icon-blog"])}>
                <a href="https://www.sapo.vn/blog" target="_blank" rel="noopener noreferrer" aria-label="Blog sapo">
                  <i className="fa fa-weixin"></i>
                </a>
              </li>
            </ul>
            <div className={styles["contact-info"]}>
              <p className="text-uppercase">Công ty cổ phần công nghệ Sapo (Sapo Technology JSC)</p>
              <p>
                <span>
                  Trụ sở <i className="fa fa-map-marker"></i>{" "}
                </span>
                Tầng 6 - Tòa nhà Ladeco - 266 Đội Cấn - Phường Liễu Giai - Quận Ba Đình - TP Hà Nội
              </p>
              {currentDate < expirationDate1 ? (
              <p>
              <span className="d-xl-inline d-block">Chi nhánh </span>
              <i className="fa fa-map-marker"></i> Lầu 3 - Tòa nhà Lữ Gia - Số 70 Lữ Gia - Phường 15 - Quận 11 - TP Hồ Chí Minh
              </p>
              ) : (
                <p>
                <span className="d-xl-inline d-block">Chi nhánh </span>
                <i className="fa fa-map-marker"></i> Lầu 5, Tòa Lữ Gia, Số 70 Lữ Gia, Quận 11, TP. HCM
              </p>
              )}
             
              <p>
                <span style={{ visibility: "hidden" }} className="d-none d-xl-inline">
                  Chi nhánh{" "}
                </span>
                <i className="fa fa-map-marker"></i> Số 83 Đường Xô Viết Nghệ Tĩnh, Phường Khuê Trung - Quận Cẩm Lệ - TP Đà Nẵng
              </p>
              <p>
                <span style={{ visibility: "hidden" }} className="d-none d-xl-inline">
                  Chi nhánh{" "}
                </span>
                <i className="fa fa-map-marker"></i>{" "}
                <Link href="https://www.sapo.vn/lien-he.html" style={{ textDecoration: "underline" }} target="_blank" rel="noopener noreferrer">
                  Xem thêm 24 chi nhánh của Sapo trên toàn quốc
                </Link>
              </p>
              <p>
                <strong>Tổng đài tư vấn mua hàng: </strong>
                <b>1800 6750</b>
              </p>
              <p>
                <strong>Tổng đài hỗ trợ sử dụng: </strong>
                <b>1900 6750</b>
              </p>
              <p>
                <strong>Tổng đài hỗ trợ vận chuyển: </strong>
                <b>1900 6719</b>
              </p>
              <p>
                <span>Email: </span>
                <b>support@sapo.vn</b>
              </p>
              <p>Từ 7h00 – 22h00 các ngày từ thứ 2 đến Chủ nhật</p>
              <p>
                <span>Liên hệ hợp tác </span>
              </p>
              <p>
                <span>Tel: </span>
                <b>(84-24) 7308 6880 (máy lẻ 333)</b>
              </p>
              <p>
                <span>Email: </span>
                <b>partner@sapo.vn</b>
              </p>
            </div>
            <a href="//support.sapo.vn/quy-dinh-su-dung" target="_blank" rel="noopener noreferrer" className={styles["footer-bct-hanggia"]}>
              <Image
                src={process.env.NEXT_PUBLIC_BASE_PATH + "/images/bct-hanggia.webp"}
                alt="Chứng nhận nói không với hàng giả - Bộ Công Thương"
                width="44"
                height="44"
              />
            </a>
            <a href="http://online.gov.vn/Home/WebDetails/44190" target="_blank" rel="noopener noreferrer" className={styles["footer-bct"]}>
              <Image src={process.env.NEXT_PUBLIC_BASE_PATH + "/images/bocongthuong.webp"} alt="Chứng nhận Bộ Công Thương" width="136" height="41" />
            </a>

            <a
              href="https://www.dmca.com/Protection/Status.aspx?ID=61b39e70-9133-4cda-9add-9a939796a1bf"
              target="_blank"
              rel="noopener noreferrer"
              className={styles["footer-dmca"]}
            >
              <Image width="120" height="43" src={process.env.NEXT_PUBLIC_BASE_PATH + "/images/icon-dmca.webp"} alt="Chứng nhận Bộ Công Thương" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterAddress;
