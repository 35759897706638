import classNames from "classnames";
import React, { useState } from "react";
import { IMenuFooter, modelMenuFooter } from "./data";
import styles from "./footer.module.scss";
const FooterMenu = () => {
  const [dataMenu] = useState<IMenuFooter[]>(modelMenuFooter);
  return (
    <div className={styles["footer-menu"]}>
      <div className="container">
        <div className="row">
          {dataMenu.map((footer, index) => (
            <React.Fragment key={index}>
              <div
                className={classNames("col-lg col-lg-3 col-md-6 col-6", {
                  "d-block d-lg-none": index === 5,
                })}
              >
                <div className={styles.title}>{footer.title}</div>
                <ul>
                  {footer.menu.map((m, i) => (
                    <li key={i}>
                      <a href={m.href} target="_blank" rel={`noreferrer ${m.rel}`}>
                        {m.title}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              {(index + 1) % 2 === 0 && dataMenu[index + 1] ? (
                <hr
                  style={{ border: "1px solid #24282e", borderTopColor: "#000", width: "100%", margin: "25px 0 30px" }}
                  className="d-block d-md-none"
                />
              ) : (
                ""
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FooterMenu;
