import React from "react";
import styles from "../headers/header.module.scss";
import { IBaseBox } from "../../model/base";
import Link from "next/link";
interface SildeStyleOneProps {
  data: IBaseBox[];
  title?: string;
  onClick?: () => void;
}
import Image from "next/legacy/image";

export function MobileTab(props: SildeStyleOneProps) {
  const { data, title, onClick } = props;

  return (
    <>
      <div className={styles.title}>
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 299.995 299.995"
          xmlSpace="preserve"
        >
          <g>
            <path d="M149.995,0C67.156,0,0,67.158,0,149.995s67.156,150,149.995,150s150-67.163,150-150S232.834,0,149.995,0z M214.842,178.524H151.25c-0.215,0-0.415-0.052-0.628-0.06c-0.213,0.01-0.412,0.06-0.628,0.06 c-5.729,0-10.374-4.645-10.374-10.374V62.249c0-5.729,4.645-10.374,10.374-10.374s10.374,4.645,10.374,10.374v95.527h54.47 c5.729,0,10.374,4.645,10.374,10.374C225.212,173.879,220.571,178.524,214.842,178.524z"></path>
          </g>
        </svg>
        <span>{title}</span>
      </div>
      <div className={styles.list_post}>
        {data?.length > 0 &&
          data?.map((post, index) => {
            return (
              <div key={index} className={styles.item}>
                <div className={styles.thumb_image}>
                  <Link href={`/${post.alias}`} title={post?.title} onClick={() => onClick()}>
                    <Image
                      src={
                        post?.image_src ? process.env.NEXT_PUBLIC_BASE_IMAGE_URL + `/articles/${post?.image_src}` : process.env.NEXT_PUBLIC_NO_IMAGE
                      }
                      alt={post.title}
                      width="80"
                      height="80"
                      objectFit="cover"
                      quality={100}
                      className="image"
                    />
                  </Link>
                </div>
                <div className={styles.post_info}>
                  <h3>
                    <Link href={`/${post.alias}`} title={post?.title} onClick={() => onClick()}>
                      {post?.title}
                    </Link>
                  </h3>
                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 469.33 320">
                      <g>
                        <path d="M234.67,96a64,64,0,1,0,64,64A64,64,0,0,0,234.67,96Z"></path>
                        <path d="M234.67,0C128,0,36.91,66.35,0,160c36.91,93.65,128,160,234.67,160s197.76-66.35,234.66-160C432.43,66.35,341.44,0,234.67,0Zm0,266.67A106.67,106.67,0,1,1,341.33,160,106.71,106.71,0,0,1,234.67,266.67Z"></path>
                      </g>
                    </svg>
                    {post?.article_view_counts?.count || 0} lượt xem
                  </span>
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
}
