import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import classnames from "classnames";
import { MobileTab } from "../mobileTab/mobileTab";
import styles from "./header.module.scss";
import { classDivisionCate, useOnClickOutsideModal } from "../../util/functionGlobal";
import { useRouter } from "next/router";
import { ICategory } from "./../../model/categories";
import Link from "next/link";
import { IBaseBox } from "../../model/base";
import { dataMenuFake } from "./fakeDataMenu";
interface TabBarMobileProps {
  dataToday: IBaseBox[];
}

export default function TabBarMobile(props: TabBarMobileProps) {
  const { dataToday } = props;
  const menuCategory = classDivisionCate(JSON.parse(JSON.stringify(dataMenuFake)));

  const ref = useRef();
  const router = useRouter();
  const [keyword, setKeyWord] = useState<string>("");
  const [clickInput, setClickInput] = useState<boolean>(false);
  const [showCate, setShowCate] = useState<boolean>(false);
  const [showTodayRead, setShowTodayRead] = useState<boolean>(false);
  const refTodayRead = useRef();
  const refCate = useRef();
  const [showMenu, setShowMenu] = useState<number[]>([]);

  useOnClickOutsideModal(ref, () => setClickInput(false), "show-search");

  const getTop = () => {
    const el = document.getElementById("nav-menu-mb");
    if (el) {
      return el.getBoundingClientRect().y + "px";
    } else {
      return 0 + "px";
    }
  };

  useEffect(() => {
    if (showTodayRead || showCate) {
      document.getElementById("nav-menu-mb").style.zIndex = "99999";
      document.querySelector("body").classList.add("disable-scroll");
    } else {
      document.getElementById("nav-menu-mb").removeAttribute("style");
      document.querySelector("body").classList.remove("disable-scroll");
    }
    return () => {
      document.querySelector("body").classList.remove("disable-scroll");
    };
  }, [showTodayRead, showCate]);

  const handleChange = (e) => {
    setKeyWord(e.target.value);
  };
  const clickSearch = (status: boolean) => {
    setClickInput(() => !status);
    setShowTodayRead(false);
    setShowCate(false);
    if (!status) {
      setTimeout(() => {
        const input = document.getElementById("input-search");
        if (input) {
          input.focus();
        }
      }, 200);
    }
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
    if (keyword.trim()) {
      router.push(`/?query=` + keyword.trim());
    }
    setClickInput(false);
  };

  const onClickShowMenu = (id: number) => {
    let showMenuCopy: number[] = JSON.parse(JSON.stringify(showMenu));
    const findId = showMenuCopy.find((item) => item === id);
    if (findId) {
      showMenuCopy.splice(showMenuCopy.indexOf(showMenuCopy.find((list) => list === id)), 1);
      setShowMenu(showMenuCopy);
    } else {
      setShowMenu([...showMenu, id]);
    }
  };

  const rendMenuCategory = (categories: ICategory[], i?: number, check?: boolean) => {
    if (!i) {
      i = 0;
    }
    i++;
    return categories?.map((item, index) => (
      <li key={index}>
        <div className={styles["row-item"]}>
          {item?.alias?.includes("/") ? (
            !check && item.kids.length > 0 ? (
              <a
                href={`${process.env.NEXT_PUBLIC_BASE_URL + process.env.NEXT_PUBLIC_BASE_PATH}/${item?.alias}`}
                onClick={(e) => {
                  setShowCate(false);
                }}
                title={item.title}
              >
                {item.title}
              </a>
            ) : (check && item.kids.length === 0) || item.alias === "tra-cuu" ? (
              <a
                href={`${process.env.NEXT_PUBLIC_BASE_URL + process.env.NEXT_PUBLIC_BASE_PATH}/${item?.alias}`}
                onClick={(e) => {
                  setShowCate(false);
                }}
                title={item.title}
              >
                {item.title}
              </a>
            ) : (
              <Link
                href={`/${item.alias}`}
                onClick={(e) => {
                  setShowCate(false);
                }}
                title={item.title}
              >
                {item.title}
              </Link>
            )
          ) : !check && item.kids.length > 0 ? (
            <Link
              href={`/${item.alias}`}
              onClick={(e) => {
                setShowCate(false);
              }}
              title={item.title}
            >
              {item.title}
            </Link>
          ) : (check && item.kids.length === 0) || item.alias === "tra-cuu" ? (
            <Link
              href={`/${item.alias}`}
              onClick={(e) => {
                setShowCate(false);
              }}
              title={item.title}
            >
              {item.title}
            </Link>
          ) : null}

          {item?.kids.length > 0 ? (
            <>
              <span
                className={classNames(styles["svg-open"], {
                  [styles["active"]]: showMenu.find((menu) => menu === item.id),
                })}
                onClick={() => onClickShowMenu(item.id)}
              >
                <svg
                  version="1.1"
                  id="Capa_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 213.333 213.333"
                  xmlSpace="preserve"
                >
                  <g>
                    <g>
                      <polygon points="0,53.333 106.667,160 213.333,53.333" />
                    </g>
                  </g>
                </svg>
              </span>
            </>
          ) : (
            ""
          )}
        </div>
        {showMenu.find((menu) => menu === item.id) ? (
          <>
            <ul className={styles["menu-child"]}>{rendMenuCategory(item.kids, i, true)}</ul>
          </>
        ) : (
          ""
        )}
      </li>
    ));
  };

  return (
    <>
      <div id="nav-menu-mb" className={classNames(styles["menu_blog_mobile"], "d-lg-none d-block")}>
        <div className={styles.nav_mobile}>
          <Link href="/">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              width="20px"
              height="20px"
              viewBox="0 0 20 17"
              version="1.1"
            >
              <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g transform="translate(-816.000000, -289.000000)">
                  <g transform="translate(100.000000, 100.000000)">
                    <g transform="translate(714.000000, 186.000000)">
                      <g transform="translate(0.000000, 0.000000)">
                        <polygon points="0 0 24 0 24 24 0 24" />
                        <path
                          d="M10,19 L10,14 L14,14 L14,19 C14,19.55 14.45,20 15,20 L18,20 C18.55,20 19,19.55 19,19 L19,12 L20.7,12 C21.16,12 21.38,11.43 21.03,11.13 L12.67,3.6 C12.29,3.26 11.71,3.26 11.33,3.6 L2.97,11.13 C2.63,11.43 2.84,12 3.3,12 L5,12 L5,19 C5,19.55 5.45,20 6,20 L9,20 C9.55,20 10,19.55 10,19 Z"
                          fill="#fff"
                        />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </Link>
          {showCate === false ? (
            <div
              data-tab="category"
              className="show-cate"
              title="Danh mục"
              onClick={() => {
                setShowCate(true);
                setShowTodayRead(false);
              }}
            >
              <span>Danh mục</span>
              <svg
                version="1.1"
                id="Capa_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 213.333 213.333"
                xmlSpace="preserve"
                className={styles["svg-open"]}
              >
                <g>
                  <g>
                    <polygon points="0,53.333 106.667,160 213.333,53.333" />
                  </g>
                </g>
              </svg>
            </div>
          ) : (
            <div
              className={classnames({
                [styles["svg-x-open"]]: showCate === true,
              })}
              onClick={(e) => {
                setShowCate(false);
              }}
            >
              <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 512.001 512.001"
                xmlSpace="preserve"
              >
                <g>
                  <path d="M284.286,256.002L506.143,34.144c7.811-7.811,7.811-20.475,0-28.285c-7.811-7.81-20.475-7.811-28.285,0L256,227.717 L34.143,5.859c-7.811-7.811-20.475-7.811-28.285,0c-7.81,7.811-7.811,20.475,0,28.285l221.857,221.857L5.858,477.859 c-7.811,7.811-7.811,20.475,0,28.285c3.905,3.905,9.024,5.857,14.143,5.857c5.119,0,10.237-1.952,14.143-5.857L256,284.287 l221.857,221.857c3.905,3.905,9.024,5.857,14.143,5.857s10.237-1.952,14.143-5.857c7.811-7.811,7.811-20.475,0-28.285 L284.286,256.002z" />
                </g>
              </svg>
            </div>
          )}

          {showTodayRead === false ? (
            <div
              data-tab="today-reading"
              className="show-top"
              title="Đọc gì hôm nay"
              onClick={(e) => {
                setShowTodayRead(true);
                setShowCate(false);
              }}
            >
              <span>Đọc gì hôm nay</span>
              <svg
                version="1.1"
                id="Capa_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 213.333 213.333"
                xmlSpace="preserve"
                className={styles["svg-open"]}
              >
                <g>
                  <g>
                    <polygon points="0,53.333 106.667,160 213.333,53.333" />
                  </g>
                </g>
              </svg>
            </div>
          ) : (
            <div
              className={classnames({
                [styles["svg-x-open"]]: showTodayRead === true,
              })}
              onClick={(e) => {
                setShowTodayRead(false);
              }}
            >
              <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 512.001 512.001"
                xmlSpace="preserve"
              >
                <g>
                  <path d="M284.286,256.002L506.143,34.144c7.811-7.811,7.811-20.475,0-28.285c-7.811-7.81-20.475-7.811-28.285,0L256,227.717 L34.143,5.859c-7.811-7.811-20.475-7.811-28.285,0c-7.81,7.811-7.811,20.475,0,28.285l221.857,221.857L5.858,477.859 c-7.811,7.811-7.811,20.475,0,28.285c3.905,3.905,9.024,5.857,14.143,5.857c5.119,0,10.237-1.952,14.143-5.857L256,284.287 l221.857,221.857c3.905,3.905,9.024,5.857,14.143,5.857s10.237-1.952,14.143-5.857c7.811-7.811,7.811-20.475,0-28.285 L284.286,256.002z" />
                </g>
              </svg>
            </div>
          )}

          <div className={styles["search-form"]}>
            <label onClick={() => clickSearch(clickInput)} className={classnames(styles["btn-search"], styles[clickInput ? "show-search" : ""])}>
              {clickInput ? (
                <svg
                  className={styles["icon-close"]}
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 512.001 512.001"
                  xmlSpace="preserve"
                >
                  <g>
                    <path d="M284.286,256.002L506.143,34.144c7.811-7.811,7.811-20.475,0-28.285c-7.811-7.81-20.475-7.811-28.285,0L256,227.717 L34.143,5.859c-7.811-7.811-20.475-7.811-28.285,0c-7.81,7.811-7.811,20.475,0,28.285l221.857,221.857L5.858,477.859 c-7.811,7.811-7.811,20.475,0,28.285c3.905,3.905,9.024,5.857,14.143,5.857c5.119,0,10.237-1.952,14.143-5.857L256,284.287 l221.857,221.857c3.905,3.905,9.024,5.857,14.143,5.857s10.237-1.952,14.143-5.857c7.811-7.811,7.811-20.475,0-28.285 L284.286,256.002z" />
                  </g>
                </svg>
              ) : (
                <svg
                  className={styles["icon-search"]}
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  version="1.1"
                  viewBox="0 0 129 129"
                >
                  <g>
                    <path d="M51.6,96.7c11,0,21-3.9,28.8-10.5l35,35c0.8,0.8,1.8,1.2,2.9,1.2s2.1-0.4,2.9-1.2c1.6-1.6,1.6-4.2,0-5.8l-35-35   c6.5-7.8,10.5-17.9,10.5-28.8c0-24.9-20.2-45.1-45.1-45.1C26.8,6.5,6.5,26.8,6.5,51.6C6.5,76.5,26.8,96.7,51.6,96.7z M51.6,14.7   c20.4,0,36.9,16.6,36.9,36.9C88.5,72,72,88.5,51.6,88.5c-20.4,0-36.9-16.6-36.9-36.9C14.7,31.3,31.3,14.7,51.6,14.7z" />
                  </g>
                </svg>
              )}
            </label>
            {clickInput && (
              <form ref={ref} id={classnames({ [styles.searchform]: clickInput })} action="" onSubmit={onFormSubmit}>
                <input type="text" id="input-search" name="input-search" placeholder="Nhập từ khóa ..." onChange={handleChange} value={keyword} />
                <button type="submit">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" viewBox="0 0 129 129">
                    <g>
                      <path d="M51.6,96.7c11,0,21-3.9,28.8-10.5l35,35c0.8,0.8,1.8,1.2,2.9,1.2s2.1-0.4,2.9-1.2c1.6-1.6,1.6-4.2,0-5.8l-35-35   c6.5-7.8,10.5-17.9,10.5-28.8c0-24.9-20.2-45.1-45.1-45.1C26.8,6.5,6.5,26.8,6.5,51.6C6.5,76.5,26.8,96.7,51.6,96.7z M51.6,14.7   c20.4,0,36.9,16.6,36.9,36.9C88.5,72,72,88.5,51.6,88.5c-20.4,0-36.9-16.6-36.9-36.9C14.7,31.3,31.3,14.7,51.6,14.7z" />
                    </g>
                  </svg>
                </button>
              </form>
            )}
          </div>
        </div>
        {showCate && (
          <div ref={refCate} className={classnames({ [styles.blog_cate]: showCate === true })} style={{ height: `calc(100vh - ${getTop()})` }}>
            <ul>{rendMenuCategory(menuCategory, null, false)}</ul>
          </div>
        )}
        {showTodayRead && (
          <div
            ref={refTodayRead}
            className={classnames({
              [styles.today_reading]: showTodayRead === true,
            })}
            style={{ height: `calc(100vh - ${getTop()})` }}
          >
            {dataToday?.length > 0 && (
              <MobileTab
                title="Đọc gì hôm nay"
                data={dataToday}
                onClick={() => {
                  setShowTodayRead(false);
                  document.querySelector("body").classList.remove("disable-scroll");
                }}
              />
            )}
          </div>
        )}
      </div>
    </>
  );
}
