import MenuMobile from "./menu-mobile";
import React, { useContext, useEffect, useState } from "react";
import { useWindowSize } from "../../util/functionGlobal";
import HeaderPc from "./header-pc";
import BannerHeader from "../bannerHeader/bannerHeader";
import { IBaseBox } from "../../model/base";
import TabBarMobile from "./tabBarMobile";
interface HeaderProps {
  showBanner: boolean;
  bannerCovid?: boolean;
}

const Header = (props: HeaderProps) => {
  const { showBanner, bannerCovid } = props;
  const [showMenuMobile, setShowMenuMobile] = useState(false);
  const { width } = useWindowSize();
  const [dataToday, setDataToday] = useState<IBaseBox[]>([]);
  const openMenu = () => {
    setShowMenuMobile(true);
  };
  const closeMenu = () => {
    setShowMenuMobile(false);
  };
  useEffect(() => {
    if (!dataToday || dataToday.length > 0) {
    } else {
      fetch(`/blog/api/get_random`)
        .then((response) => response?.json())
        .then((data) => {
          setDataToday(data.data);
        });
    }
  }, []);

  return (
    <>
      <HeaderPc onOpenMenu={openMenu} width={width} />
      {showBanner ? (
        <BannerHeader
          title={process.browser && window.screen.availWidth < 767 ? "Blog" : "Sapo Blog"}
          description="Chia sẻ kinh nghiệm quản lý và bán hàng đa kênh thiết thực nhất"
          bannerCovid={bannerCovid}
        />
      ) : (
        ""
      )}
      {width < 992 && <TabBarMobile dataToday={dataToday} />}
    </>
  );
};

export default Header;
