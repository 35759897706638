import React, { useState } from "react";
import classnames from "classnames";
import styles from "./footer.module.scss";
const QuickRegistration = () => {
  const [alias, setAlias] = useState<string>("");
  const onInputStoreName = () => {};

  const trialBtn = '<a onclick="showModalTrial(this, 1, false);" href="javascript:;"> Dùng thử miễn phí </a>';
  return (
    <div className={classnames("quick-registration", "register-bottom")}>
      <div className="container">
        <div className="bg-register">
          <div className="heading">Bắt đầu dùng thử miễn phí 7 ngày</div>
          <label htmlFor="site_name_bottom">Để trải nghiệm nền tảng quản lý bán hàng đa kênh được hơn {process.env.NEXT_PUBLIC_TOTAL_MERCHANT} khách hàng sử dụng</label>
          <div className="reg-form">
            <input
              id="site_name_bottom"
              className="input-site-name d-none d-md-inline-block"
              type="text"
              value={alias}
              placeholder="Nhập tên cửa hàng/doanh nghiệp của bạn"
              onKeyPress={() => onInputStoreName()}
              onChange={(e) => setAlias(e.target.value)}
            />
            <span dangerouslySetInnerHTML={{ __html: trialBtn }} className={styles["btn-registration"]} />
          </div>
        </div>
      </div>
    </div>
  );
};
export default QuickRegistration;
