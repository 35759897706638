import styles from "./headerv3.module.scss";
import Nav from "./nav-pc";
import { useEffect, useRef, useState } from "react";
import { useWindowSize } from "../../util/functionGlobal";
import Link from "next/link";
import Image from "next/legacy/image";
import moment from "moment";
import classNames from "classnames";


const HeaderPc = (props) => {
  const mainMenuRef = useRef(null);
  const backdropRef = useRef(null);
  const { width } = useWindowSize();
  const [showMenuMobile, setShowMenuMobile] = useState(false);
  const [showBackdropMenu, setShowBackdropMenu] = useState(false);
  const [openId, setOpenId] = useState(null);
  const [dataBlogdNews, setDataBlogdNews] = useState([]);
  const [dataFeaturedNews, setDataFeaturedNews] = useState([]);



  const closeMenu = () => {
    setShowMenuMobile(false);
    setShowBackdropMenu(false);
  };

  const openMenu = () => {
    setShowMenuMobile(true);
    setShowBackdropMenu(true);
  };

  const closeOver = () => {
    setShowMenuMobile(false);
    setShowBackdropMenu(false);
  };

  const toggleCollapse = (id) => {
    setOpenId(openId === id ? null : id);
  };

  const showBackdrop = () => {
    if (!backdropRef.current) {
      backdropRef.current = document.createElement('div');
      backdropRef.current.className = styles['backdrop-submenu'];
      document.body.appendChild(backdropRef.current);
    }
  };

  const hideBackdrop = () => {
    if (backdropRef.current) {
      backdropRef.current.remove();
      backdropRef.current = null;
    }
  };

  const debounce = (fn, ms) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        fn.apply(context, args);
      }, ms);
    };
  };


  useEffect(() => {
    fetch(`/blog/api/get_feed?limit=5&category=31`)
      .then((response) => response?.json())
      .then((data) => {
        setDataFeaturedNews(data.data?.length ? data.data : []);
      });
    fetch(`/blog/api/get_feed?limit=6&notIn=27,30,25,31,32,33,34,35,36`)
      .then((response) => response?.json())
      .then((data) => {
        setDataBlogdNews(data.data?.length ? data.data : []);
      });
  }, [])

  useEffect(() => {
    const mainMenu = mainMenuRef.current;
    const header = document.querySelector(`.${styles.header}`);
    if (!mainMenu || !header) return;

    const handleMouseEnter = (event) => {

      header.classList.add(styles['color-header']);
      mainMenu.classList.add(styles['after']);
      if (event.target.classList.contains('hasChild')) {
        showBackdrop();
      }
    };

    const handleMouseLeave = () => {
      header.classList.remove(styles['color-header']);
      mainMenu.classList.remove(styles['after']);
      hideBackdrop();
    };

    const menuItems = mainMenu.querySelectorAll('.li');
    if (width > 1200) {
      menuItems.forEach(item => {
        item.addEventListener('mouseenter', handleMouseEnter);
        item.addEventListener('mouseleave', handleMouseLeave);
      });
    }

    return () => {
      if (width > 1200) {
        menuItems.forEach(item => {
          item.addEventListener('mouseenter', handleMouseEnter);
          item.addEventListener('mouseleave', handleMouseLeave);
        });
      }
    };
  }, [width]);

  const trialBtn = `<a class="btn-registration" href="#" onclick="showModalTrial(this, 4, false)">Đăng ký</a>`;
  const trialBtnMobile = `<a class="${styles['btn-registration']}" href="#" onclick="showModalTrial(this, 1, false)"> <span class="text">Dùng thử miễn phí</span> <span class="${styles["icon"]}"> <svg><use xmlns:xlink="http://www.w3.org/1999/xlink" href="#IconArrow"></use></svg> </span> </a>`;
  return (
    <>
      <div id="header" className={`${styles["header"]} ${styles.WEB} ${styles['active-child']}`}>
        <div className={`${styles['main-header']} d-flex align-items-center flex-wrap`}>
          <div className={styles.container}>
            <div className="row">
              <div className="col-xl-2 col-8 d-flex align-items-center ">
                <a id="logo" className={`${styles['main-logo']} d-xl-block d-none`} href="/">
                  <img src="//www.sapo.vn/Themes/Portal/Default/StylesV2/images/logo/Sapo-logo.svg?v=222" className={styles["main-logo"]} alt="Sapo logo" />
                </a>
                <a className={`${styles['main-logo']} d-block d-xl-none`} href="/">
                  <img src="//www.sapo.vn/Themes/Portal/Default/StylesV2/images/logo/Sapo-logo.svg?v=222" alt="Sapo logo" />
                </a>
              </div>
              <div className={`${styles["position-initial"]} col-xl-10 col-4 d-flex justify-content-end justify-space-beetwen-index   align-items-center`}>
                <ul className={`${styles['main-menu']} ${styles["update-menu"]} ${styles["after"]} d-none d-xl-flex align-items-center`} ref={mainMenuRef}>
                  <li className="hasChild li">
                    <a href="#">Giải pháp <i className="fa fa-angle-down"></i> </a>
                    <div className={`${styles['sub-menu']} ${styles['sub-menu-solution']}`}>
                      <div className={`${styles["grid-template-columns-4"]} ${styles["d-grid"]} ${styles["container"]}`}>
                        <div className={`${styles['item']}`}>
                          <h3 className="d-flex align-items-center">
                            <svg><use xlinkHref="#IconSalesChannel"></use></svg>
                            Bán hàng đa kênh
                          </h3>
                          <ul>
                            <li>
                              <a href="https://www.sapo.vn/phan-mem-quan-ly-ban-hang.html" title="Bán tại cửa hàng">
                                <strong>Bán tại cửa hàng<svg><use xlinkHref="#IconArrowRight"></use></svg></strong>
                                <p>Tính tiền và in hóa đơn nhanh chóng</p>
                              </a>
                            </li>
                            <li>
                              <a href="https://www.sapo.vn/quan-ly-ban-hang-online.html" title="Bán trên sàn TMĐT">
                                <strong>Bán trên sàn TMĐT<svg><use xlinkHref="#IconArrowRight"></use></svg></strong>
                                <p>Tăng doanh thu từ Shopee, TiktokShop, Tiki,...</p>
                              </a>
                            </li>
                            <li>
                              <a href="https://www.sapo.vn/phan-mem-quan-ly-ban-hang-fanpage-facebook.html" title="Bán trên mạng xã hội">
                                <strong>Bán trên mạng xã hội<svg><use xlinkHref="#IconArrowRight"></use></svg></strong>
                                <p>Tối ưu bán hàng trên Facebook & Instagram</p>
                              </a>
                            </li>
                            <li>
                              <a href="https://www.sapo.vn/thiet-ke-website-ban-hang.html" title="Bán hàng trên Website">
                                <strong>Bán hàng trên Website<svg><use xlinkHref="#IconArrowRight"></use></svg></strong>
                                <p>Thiết kế website bán hàng chuyên nghiệp</p>
                              </a>
                            </li>
                            <li>
                              <a href="https://www.sapo.vn/omnichannel.html" title="Bán hàng đa kênh">
                                <strong>Bán hàng đa kênh<svg><use xlinkHref="#IconArrowRight"></use></svg></strong>
                                <p>Bán đa kênh từ Online đến Offline</p>
                              </a>
                            </li>
                          </ul>
                        </div>
                        <div className={styles["item"]}>
                          <h3 className="d-flex align-items-center">
                            <svg><use xlinkHref="#IconManagementChannel"></use></svg>
                            Quản lý đa kênh
                          </h3>
                          <ul>
                            <li>
                              <a href="https://www.sapo.vn/phan-mem-quan-ly-kho.html" title="Quản lý kho">
                                <strong>Quản lý kho<svg><use xlinkHref="#IconArrowRight"></use></svg></strong>
                                <p>Nắm bắt chính xác lượng hàng trong kho</p>
                              </a>
                            </li>
                            <li>
                              <a href="https://www.sapo.vn/cham-soc-khach-hang.html" title="Quản lý khách hàng">
                                <strong>Quản lý khách hàng<svg><use xlinkHref="#IconArrowRight"></use></svg></strong>
                                <p>Lưu thông tin và chăm sóc sau bán</p>
                              </a>
                            </li>
                            <li>
                              <a href="https://www.sapo.vn/phan-mem-quan-ly-nhan-vien.html" title="Quản lý nhân viên">
                                <strong>Quản lý nhân viên<svg><use xlinkHref="#IconArrowRight"></use></svg></strong>
                                <p>Phân quyền, theo dõi doanh số nhân viên</p>
                              </a>
                            </li>
                            <li>
                              <a href="https://www.sapo.vn/quan-ly-giao-hang.html" title="Quản lý giao hàng">
                                <strong>Quản lý giao hàng<svg><use xlinkHref="#IconArrowRight"></use></svg></strong>
                                <p>Tự động hóa quy trình giao hàng</p>
                              </a>
                            </li>
                            <li>
                              <a href="https://www.sapo.vn/tinh-nang-noi-bat-omnichannel.html" title="Quản lý đa kênh">
                                <strong>Quản lý đa kênh<svg><use xlinkHref="#IconArrowRight"></use></svg></strong>
                                <p>Quản lý tập trung tất cả các kênh</p>
                              </a>
                            </li>
                          </ul>
                        </div>
                        <div className={styles["item"]}>
                          <h3 className="d-flex align-items-center">
                            <svg><use xlinkHref="#IconManagementFood"></use></svg>
                            Quản lý dịch vụ ăn uống
                          </h3>
                          <ul>
                            <li>
                              <a href="https://www.sapo.vn/phan-mem-quan-ly-nha-hang.html" title="Quản lý nhà hàng, quán ăn">
                                <strong>Quản lý nhà hàng, quán ăn<svg><use xlinkHref="#IconArrowRight"></use></svg></strong>
                                <p>Tính tiền và quản lý nhà hàng toàn diện</p>
                              </a>
                            </li>
                            <li>
                              <a href="https://www.sapo.vn/phan-mem-quan-ly-quan-cafe.html" title="Quản lý quán trà sữa, trà chanh">
                                <strong>Quản lý quán cafe, tiệm bánh<svg><use xlinkHref="#IconArrowRight"></use></svg></strong>
                                <p>Order nhanh, quản lý doanh thu chính xác</p>
                              </a>
                            </li>
                            <li>
                              <a href="https://www.sapo.vn/phan-mem-quan-ly-quan-tra-sua.html" title="Quản lý quán trà sữa, trà chanh">
                                <strong>Quản lý quán trà sữa, trà chanh<svg><use xlinkHref="#IconArrowRight"></use></svg></strong>
                                <p>Bán hàng dễ dàng, quản lý topping chi tiết</p>
                              </a>
                            </li>
                            <li>
                              <a href="//www.sapo.vn/phan-mem-quan-ly-quan-bida.html" title="Quản lý quán bida">
                                <strong>Quản lý quán bida<svg><use xlinkHref="#IconArrowRight"></use></svg></strong>
                                <p>Đặt bàn nhanh chóng, quản lý từ xa dễ dàng</p>
                              </a>
                            </li>
                            <li>
                              <a href="https://www.sapo.vn/phan-mem-quan-ly-quan-karaoke.html" title="Quản lý quán bar, karaoke">
                                <strong>Quản lý quán bar, karaoke<svg><use xlinkHref="#IconArrowRight"></use></svg></strong>
                                <p>Thiết lập giá theo giờ, tính tiền linh hoạt</p>
                              </a>
                            </li>
                          </ul>
                        </div>
                        <div className={`${styles["item"]} ${styles["item-big-business"]}`}>
                          <h3 className="d-flex align-items-center">
                            <svg><use xlinkHref="#IconBigBusiness"></use></svg>
                            Doanh nghiệp lớn
                          </h3>
                          <p>
                            <a href="https://www.sapo.vn/enterprise" title="Doanh nghiệp lớn">
                              Giải pháp chuyển đổi số toàn diện từ bán hàng đa kênh đến vận hành quản lý tập trung dành riêng cho các doanh nghiệp lớn
                            </a>
                          </p>
                          <a href="https://www.sapo.vn/enterprise" title="Doanh nghiệp lớn">
                            <img src={process.env.NEXT_PUBLIC_BASE_PATH + "/images/img-big-business.png?v=1"}
                              alt="Doanh nghiệp lớn" />
                          </a>
                        </div>

                      </div>
                      <div className={styles["sapo-ecosystem"]}>
                        <div className={`${styles["container"]} ${styles["d-grid"]} ${styles["grid-template-columns-4"]}`}>
                          <div className={styles.item}>
                            <a href="https://www.sapo.vn/sapo-express.html" title="Sapo Express">
                              <strong>Sapo Express <svg><use xlinkHref="#IconArrowRight"></use></svg></strong>
                              <p>
                                Giải pháp giảm phí ship và xử lý đơn hàng
                                nhanh hơn 70% cho cửa hàng
                              </p>
                            </a>
                          </div>
                          <div className={styles.item}>
                            <a href="https://www.sapo.vn/thanh-toan-khong-tien-mat.html" title="Sapo Pay">
                              <strong>Sapo Pay <svg><use xlinkHref="#IconArrowRight"></use></svg></strong>
                              <p>
                                Giải pháp thanh toán không tiền mặt dành riêng
                                cho khách hàng Sapo
                              </p>
                            </a>
                          </div>
                          <div className={styles.item}>
                            <a href="https://www.sapo.vn/vay-von-kinh-doanh.html" title="Sapo Money">
                              <strong>Sapo Money <svg><use xlinkHref="#IconArrowRight"></use></svg></strong>
                              <p>
                                Giải pháp vay vốn kinh doanh dành riêng cho
                                khách hàng Sapo
                              </p>
                            </a>
                          </div>
                          <div className={styles.item}>
                            <a href="https://www.sapo.vn/sapo-partner.html" title="Partner">
                              <strong>Partner <svg><use xlinkHref="#IconArrowRight"></use></svg></strong>
                              <p>
                                Trở thành đối tác và cùng phát triển hệ sinh thái
                                lớn mạnh cùng Sapo
                              </p>
                            </a>
                          </div>
                        </div>
                      </div>

                    </div>
                  </li>
                  <li className={`${styles["bang-gia"]}  no-hasChild li`}>
                    <a href="https://www.sapo.vn/bang-gia.html" title="Bảng giá">Bảng giá</a>
                  </li>
                  <li className="no-hasChild li">
                    <a href="https://www.sapo.vn/khach-hang.html" title="Khách hàng">Khách hàng</a>
                  </li>
                  <li className="hasChild li">
                    <a href="#">Sapo có gì mới <i className="fa fa-angle-down"></i> </a>
                    <div className={`${styles["sub-menu"]} ${styles["sapo-new"]}`}>
                      <div className={styles["container"]}>
                        <div className="row">
                          <div className={`${styles["item"]} col-xl-3 col-lg-3`}>
                            <h3>
                              <a className="d-flex align-items-center" href="https://www.sapo.vn/blog/tin-su-kien-sapo/sapo-update" title="Tính năng mới" target="_blank">
                                <svg><use xlinkHref="#IconNewFeature"></use></svg>
                                Tính năng mới
                              </a>
                            </h3>
                            <ul className={`${styles["blog-new-feature"]} ${styles["blog-new-feature-2"]}`}>
                              {dataFeaturedNews.map((post, index) => (
                                <li key={index}>
                                  <Link key={index} href={`${post?.alias}`} title={post.title}>
                                    {post.title}
                                  </Link>
                                </li>
                              ))}
                            </ul>
                          </div>
                          <div className={`${styles["item"]} col-xl-6 col-lg-6`}>
                            <h3>
                              <a className="d-flex align-items-center" href="https://www.sapo.vn/blog" target="_blank" title="Blog Sapo">
                                <svg><use xlinkHref="#IconBlogHeader"></use></svg>
                                Blog Sapo
                              </a>
                            </h3>
                            <div className={`${styles["block-blog"]} d-flex`}>
                              <div className={classNames(styles["blog-business-experience-first"])}>
                                {dataBlogdNews.slice(0, 1).map((post, idx) => {
                                  const key = `blogNews-${idx}`;
                                  return <div key={key} className="item">
                                    <Link href={`${post.alias}`} className={styles["thumb-blog"]}>
                                      <Image loading="eager" objectFit="cover" layout="fill" quality={100} src={post?.image_src ? post?.image_src : process.env.NEXT_PUBLIC_NO_IMAGE} alt={post?.title} />
                                    </Link>
                                    <div className={styles.info}>
                                      <div className={styles.time}>
                                        <i className="fa fa-clock-o"></i>
                                        {post.published_at ? moment(post.published_at).format("DD-MM-YYYY") : post.created_at ? moment(post.created_at).format("DD-MM-YYYY") : ""}
                                      </div>
                                      <div className={styles.title}>
                                        <a href={`${post?.alias}`}  title={post.title}>{post.title}</a>
                                      </div>
                                      <a className={styles["read-more"]} href={`/${post?.alias}`} title="Đọc tiếp">Đọc tiếp
                                        <i className="fa fa-angle-right"></i>
                                      </a>
                                    </div>
                                  </div>
                                })}
                              </div>

                              <ul className={`${styles["blog-business-experience"]} ${styles["blog-business-experience-2"]}`}>
                                {dataBlogdNews.slice(1).map((item, index) => (
                                  <li key={item.id}>
                                    <Link href={`${item?.alias}`} title={item.title}>
                                      {item.title}
                                    </Link>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>
                          <div className={`${styles["item"]} col-xl-3 col-lg-3`}>
                            <h3>
                              <a className="d-flex align-items-center" href="https://www.sapo.vn/bao-chi-noi-ve-sapo.html" title="Báo chí nói về Sapo" target="_blank">
                                <svg><use xlinkHref="#IconNewspapers"></use></svg>
                                Báo chí nói về Sapo
                              </a>
                            </h3>
                            <div className={styles["logo-newspapers"]}>
                              <img src={process.env.NEXT_PUBLIC_BASE_PATH + "/images/bao-vietnamnet.svg?v=3"} alt="Báo Vietnamnet đưa tin về Sapo" />
                              <img src={process.env.NEXT_PUBLIC_BASE_PATH + "/images/bao-dan-tri.svg?v=3"} alt="Báo Dân trí nói về Sapo" />
                              <img src={process.env.NEXT_PUBLIC_BASE_PATH + "/images/bao-vnexpress.svg?v=3"} alt="VnExpress viết về Sapo" />
                              <img src={process.env.NEXT_PUBLIC_BASE_PATH + "/images/bao-cafef.svg?v=3"} alt="Cafef nói về Sapo" />
                              <img src={process.env.NEXT_PUBLIC_BASE_PATH + "/images/bao-thanh-nien.svg?v=3"} alt="Báo Thanh Niên nói về Sapo" />
                              <img src={process.env.NEXT_PUBLIC_BASE_PATH + "/images/vtv-news.svg?v=3"} alt="VTV News đưa tin về Sapo" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>

                  <li className="hasChild li">
                    <a href="#">Thêm <i className="fa fa-angle-down"></i> </a>
                    <div className={`${styles["sub-menu"]} ${styles["sub-menu-4"]}`}>
                      <div className={`${styles["container"]} ${styles["d-grid"]} ${styles["grid-template-columns-4"]}`}>
                        <div className={styles["item"]}>
                          <h3 className="d-flex align-items-center">
                            <svg><use xlinkHref="#IconLearnSapo"></use></svg>
                            Tìm hiểu Sapo
                          </h3>
                          <p>
                            <a href="https://www.sapo.vn/ve-chung-toi.html" title="Về chúng tôi">
                              <strong>Về chúng tôi<svg className={styles.hover}><use xlinkHref="#IconArrowRight"></use></svg></strong>
                              <span>Tìm hiểu lịch sử hình thành và sứ mệnh của chúng tôi</span>
                            </a>
                          </p>
                          <p>
                            <a href="https://www.sapo.vn/profile-san-pham" title="Sapo Profile">
                              <strong>Sapo Profile <svg className={styles.hover}><use xlinkHref="#IconArrowRight"></use></svg></strong>
                              <span>Tổng quan về các dịch vụ Sapo cung cấp cho nhà bán hàng</span>
                            </a>
                          </p>
                        </div>
                        <div className={styles["item"]}>
                          <h3 className="d-flex align-items-center">
                            <svg><use xlinkHref="#IconDiscover"></use></svg>
                            Khám phá
                          </h3>
                          <p>
                            <a href="https://tuyendung.sapo.vn/" title="Tuyển dụng" target="_blank">
                              <strong>Tuyển dụng<svg className={styles.hover}><use xlinkHref="#IconArrowRight"></use></svg></strong>
                              <span>Sẵn sàng trở thành Sapo-er! Khám phá các cơ hội việc làm hot nhất</span>
                            </a>
                          </p>
                          <p>
                            <a href="https://academy.sapo.vn/" title="Sapo Academy" target="_blank">
                              <strong>Sapo Academy<svg className={styles.hover}><use xlinkHref="#IconArrowRight"></use></svg></strong>
                              <span>Nơi chia sẻ kinh nghiệm & đào tạo kinh doanh thực chiến</span>
                            </a>
                          </p>
                        </div>
                        <div className={styles["item"]}>
                          <h3 className="d-flex align-items-center">
                            <svg><use xlinkHref="#IconHelp"></use></svg>
                            Trợ giúp
                          </h3>
                          <p>
                            <a href="https://support.sapo.vn/" title="Trợ giúp" target="_blank">
                              <strong>Tài liệu hướng dẫn<svg className={styles.hover}><use xlinkHref="#IconArrowRight"></use></svg></strong>
                              <span>Tài liệu, video hướng dẫn sử dụng các dịch vụ Sapo cung cấp</span>
                            </a>
                          </p>
                          <p>
                            <a href="https://www.sapo.vn/lien-he.html" title="Liên hệ">
                              <strong>Liên hệ<svg className={styles.hover}><use xlinkHref="#IconArrowRight"></use></svg></strong>
                              <span>Hãy liên hệ với chúng tôi để được giải đáp mọi vấn đề</span>
                            </a>
                          </p>
                        </div>

                        <div className={`${styles["item"]} item-thiet-bi-ban-hang`}>
                          <h3 className="d-flex align-items-center">
                            <svg><use xlinkHref="#IconSellingMachine"></use></svg>
                            Thiết bị bán hàng
                          </h3>
                          <p>
                            <a href="https://shop.sapo.vn/" target="_blank">Thiết bị hiện đại - chính hãng - chuyên nghiệp</a>
                          </p>
                          <a href="https://shop.sapo.vn/" target="_blank">
                            <img src={process.env.NEXT_PUBLIC_BASE_PATH + "/images/img-thiet-bi-ban-hang.png?v=2"}
                              alt="Thiết bị bán hàng" />
                          </a>
                        </div>

                      </div>
                    </div>
                  </li>

                </ul>
                <ul className="d-none d-xl-flex justify-content-end">
                  <li className={`${styles["logins"]} d-flex align-items-center`}>
                    <a href="https://www.sapo.vn/dang-nhap-kenh-ban-hang.html?utm_campaign=cpn:themes-plm:header&utm_source=themes.sapo.vn&utm_medium=referral&utm_content=fm:text_link-km:-sz:&utm_term=&campaign=header_theme_sapo&_gl=1*lgb8ro*_ga*MTY4NzQ3MDk0NS4xNzEzMjYyNzE5*_ga_Y9YZPDEGP0*MTcyMzQ0NzIwMC4yNTAuMS4xNzIzNDQ5OTg3LjUzLjAuMA..*_ga_GECRBQV6JK*MTcyMzQ0NzIwMC4yNTAuMS4xNzIzNDQ5OTg3LjUzLjAuMA..*_ga_YNVPPJ8MZP*MTcyMzQ0NzIwMC4yNTAuMS4xNzIzNDQ5OTg3LjUzLjAuMA..*_ga_CDD1S5P7D4*MTcyMzQ0NzIwMC4yNTAuMS4xNzIzNDQ5OTg3LjUzLjAuMA..*_ga_8956TVT2M3*MTcyMzQ0NzIwMC4yNTAuMS4xNzIzNDQ5OTg4LjUyLjAuMA..">Đăng nhập</a>
                  </li>
                  <li dangerouslySetInnerHTML={{ __html: trialBtn }} className={styles["btn-register"]} />
                </ul>
                <a aria-label="button" href="#" className={`${styles["btn-menu"]} d-inline-block d-xl-none`} onClick={openMenu}>
                  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 384 384" xmlSpace="preserve" className={styles["menu-icon"]}><g><rect x="0" y="300" width="384" height="50"></rect><rect x="0" y="150" width="384" height="50"></rect><rect x="0" y="0" width="384" height="50"></rect></g></svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`${styles["menu-mobile"]}  ${showMenuMobile ? styles['show'] : ''} ${styles.WEB}`}>
        <div className={`${styles["logo-mobile"]}`}>
          <a href="https://www.sapo.vn/thiet-ke-website-ban-hang.html?utm_campaign=cpn%3Athemes_Web-plm%3Aheader&utm_source=themes.sapo.vn&utm_medium=referral&utm_content=fm%3Atext_link-km%3A-sz%3A&utm_term=&campaign=header_theme_sapo">
            <img src="//www.sapo.vn/Themes/Portal/Default/StylesV2/images/logo/Sapo-logo.svg?v=2" alt="Sapo logo" />
          </a>
          <a href="#" onClick={closeMenu} className={styles["btn-close-menu"]} aria-hidden="true">
            <svg className={styles["icon-close"]} version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512.001 512.001" xmlSpace="preserve"><g><path d="M284.286,256.002L506.143,34.144c7.811-7.811,7.811-20.475,0-28.285c-7.811-7.81-20.475-7.811-28.285,0L256,227.717 L34.143,5.859c-7.811-7.811-20.475-7.811-28.285,0c-7.81,7.811-7.811,20.475,0,28.285l221.857,221.857L5.858,477.859 c-7.811,7.811-7.811,20.475,0,28.285c3.905,3.905,9.024,5.857,14.143,5.857c5.119,0,10.237-1.952,14.143-5.857L256,284.287 l221.857,221.857c3.905,3.905,9.024,5.857,14.143,5.857s10.237-1.952,14.143-5.857c7.811-7.811,7.811-20.475,0-28.285 L284.286,256.002z"></path></g></svg>
          </a>
        </div>
        <div className={styles["box-scroll"]}>
          <ul className={`${styles["nav"]} ${styles["nav-bottom"]}`} id="accordion">
            <li className={styles["show-sub"]} >
              <a data-toggle="collapse" onClick={() => toggleCollapse('nav-bottom-child-1')} className={`${styles[openId === 'nav-bottom-child-1' ? 'in' : '']}`} href="#" data-target="#nav-bottom-child-1">
                <text>Giải pháp</text>
                <i className="fa fa-angle-down"></i>
              </a>
              <div id="nav-bottom-child-1" className={`collapse ${openId === 'nav-bottom-child-1' ? 'show' : ''} ${styles['panel-collapse']}`} data-parent="#accordion">
                <div className={styles["item"]} >
                  <h3 className="d-flex align-items-center">
                    <svg><use xlinkHref="#IconSalesChannel"></use></svg>
                    Bán hàng đa kênh
                  </h3>
                  <ul className={styles["sub-menu"]}>
                    <li>
                      <a href="//www.sapo.vn/phan-mem-quan-ly-ban-hang.html" title="Bán tại cửa hàng">
                        Bán tại cửa hàng
                      </a>
                    </li>
                    <li>
                      <a href="//www.sapo.vn/quan-ly-ban-hang-online.html" title="Bán trên sàn TMĐT">
                        Bán trên sàn TMĐT
                      </a>
                    </li>
                    <li>
                      <a href="//www.sapo.vn/phan-mem-quan-ly-ban-hang-fanpage-facebook.html" title="Bán trên mạng xã hội">
                        Bán trên mạng xã hội
                      </a>
                    </li>
                    <li>
                      <a href="//www.sapo.vn/thiet-ke-website-ban-hang.html" title="Bán hàng trên Website">
                        Bán hàng trên Website
                      </a>
                    </li>
                    <li>
                      <a href="//www.sapo.vn/omnichannel.html" title="Bán hàng đa kênh">
                        Bán hàng đa kênh
                      </a>
                    </li>
                  </ul>
                </div>

                <div className={styles["item"]} >
                  <h3 className="d-flex align-items-center">
                    <svg><use xlinkHref="#IconManagementChannel"></use></svg>
                    Quản lý đa kênh
                  </h3>

                  <ul className={styles["sub-menu"]}>
                    <li>
                      <a href="//www.sapo.vn/phan-mem-quan-ly-kho.html" title="Quản lý kho">
                        Quản lý kho
                      </a>
                    </li>
                    <li>
                      <a href="//www.sapo.vn/cham-soc-khach-hang.html" title="Quản lý khách hàng">
                        Quản lý khách hàng
                      </a>
                    </li>
                    <li>
                      <a href="//www.sapo.vn/phan-mem-quan-ly-nhan-vien.html" title="Quản lý nhân viên">
                        Quản lý nhân viên
                      </a>
                    </li>
                    <li>
                      <a href="//www.sapo.vn/quan-ly-giao-hang.html" title="Quản lý giao hàng">
                        Quản lý giao hàng
                      </a>
                    </li>
                    <li>
                      <a href="//www.sapo.vn/tinh-nang-noi-bat-omnichannel.html" title="Quản lý đa kênh">
                        Quản lý đa kênh
                      </a>
                    </li>
                  </ul>
                </div>
                <div className={styles["item"]} >
                  <h3 className="d-flex align-items-center">
                    <svg><use xlinkHref="#IconManagementFood"></use></svg>
                    Quản lý dịch vụ ăn uống
                  </h3>

                  <ul className={styles["sub-menu"]}>
                    <li>
                      <a href="//www.sapo.vn/phan-mem-quan-ly-nha-hang.html" title="Quản lý nhà hàng, quán ăn">
                        Quản lý nhà hàng, quán ăn
                      </a>
                    </li>
                    <li>
                      <a href="//www.sapo.vn/phan-mem-quan-ly-quan-cafe.html" title="Quản lý quán trà sữa, trà chanh">
                        Quản lý quán cafe, tiệm bánh
                      </a>
                    </li>
                    <li>
                      <a href="//www.sapo.vn/phan-mem-quan-ly-quan-tra-sua.html" title="Quản lý quán trà sữa, trà chanh">
                        Quản lý quán trà sữa, trà chanh
                      </a>
                    </li>
                    <li>
                      <a href="//www.sapo.vn/phan-mem-quan-ly-quan-bida.html" title="Quản lý quán bida">
                        Quản lý quán bida
                      </a>
                    </li>
                    <li>
                      <a href="//www.sapo.vn/phan-mem-quan-ly-quan-karaoke.html" title="Quản lý quán bar, karaoke">
                        Quản lý quán bar, karaoke
                      </a>
                    </li>
                  </ul>

                </div>

                <div className={styles["item"]}>
                  <h3 className="d-flex align-items-center">
                    <svg><use xlinkHref="#IconBigBusiness"></use></svg>
                    Doanh nghiệp lớn
                  </h3>

                  <ul className={styles["sub-menu"]}>
                    <li>
                      <a href="//www.sapo.vn/enterprise">
                        Giải pháp chuyển đổi số toàn diện từ bán hàng đa kênh đến vận hành quản lý tập trung dành riêng cho các doanh nghiệp lớn
                      </a>
                    </li>
                  </ul>

                </div>
                <div className={`${styles["item"]} item-4`}>
                  <ul className={styles["sub-menu"]}>
                    <li>
                      <a href="//www.sapo.vn/sapo-express.html" title="Sapo Express">
                        Sapo Express
                        <p>Giải pháp giảm phí ship và xử lý đơn hàng nhanh hơn 70% cho cửa hàng</p>
                      </a>
                    </li>
                    <li>
                      <a href="//www.sapo.vn/thanh-toan-khong-tien-mat.html" title="Sapo Pay">
                        Sapo Pay
                        <p>Giải pháp thanh toán không tiền mặt dành riêng cho khách hàng Sapo</p>
                      </a>
                    </li>
                    <li>
                      <a href="//www.sapo.vn/vay-von-kinh-doanh.html" title="Sapo Money">
                        Sapo Money
                        <p>Giải pháp vay vốn kinh doanh dành riêng cho khách hàng Sapo</p>
                      </a>
                    </li>
                    <li>
                      <a href="//www.sapo.vn/sapo-partner.html" title="Partner">
                        Partner
                        <p>Trở thành đối tác và cùng phát triển hệ sinh thái lớn mạnh cùng Sapo</p>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
            <li className="bang-gia">
              <a href="//www.sapo.vn/bang-gia.html">
                Bảng giá
              </a>
            </li>
            <li><a href="/khach-hang.html">Khách hàng</a></li>
            <li className={styles["show-sub"]}>
              <a data-toggle="collapse" onClick={() => toggleCollapse('nav-bottom-child-2')} className={`${styles[openId === 'nav-bottom-child-2' ? 'in' : '']}`} href="#" data-target="#nav-bottom-child-2">
                <text>Sapo có gì mới</text>
                <i className="fa fa-angle-down"></i>
              </a>
              <div id="nav-bottom-child-2" className={`collapse ${openId === 'nav-bottom-child-2' ? 'show' : ''} ${styles['panel-collapse']}`} data-parent="#accordion">
                <div className={styles["item"]}>
                  <h3>
                    <a className="d-flex align-items-center" href="https://www.sapo.vn/blog/tin-su-kien-sapo/sapo-update" target="_blank">
                      <svg><use xlinkHref="#IconNewFeature"></use></svg>
                      Tính năng mới
                    </a>
                  </h3>
                </div>

                <div className={styles["item"]}>
                  <h3>
                    <a className="d-flex align-items-center" href="https://www.sapo.vn/blog" target="_blank">
                      <svg><use xlinkHref="#IconBlogHeader"></use></svg>
                      Blog Sapo
                    </a>
                  </h3>
                </div>
                <div className={styles["item"]}>
                  <h3>
                    <a className="d-flex align-items-center" href="https://www.sapo.vn/bao-chi-noi-ve-sapo.html" target="_blank">
                      <svg><use xlinkHref="#IconNewspapers"></use></svg>
                      Báo chí nói về Sapo
                    </a>
                  </h3>
                </div>
              </div>
            </li>

            <li className={styles["show-sub"]}>
              <a data-toggle="collapse" href="#" onClick={() => toggleCollapse('nav-bottom-child-3')} className={`${styles[openId === 'nav-bottom-child-3' ? 'in' : '']}`} data-target="#nav-bottom-child-3">
                <text>Thêm</text>
                <i className="fa fa-angle-down"></i>
              </a>
              <div id="nav-bottom-child-3" className={`collapse ${openId === 'nav-bottom-child-3' ? 'show' : ''} ${styles['panel-collapse']}`} data-parent="#accordion">
                <div className={styles["item"]}>
                  <h3 className="d-flex align-items-center">
                    <svg><use xlinkHref="#IconLearnSapo"></use></svg>
                    Tìm hiểu Sapo
                  </h3>
                  <ul className={styles["sub-menu"]}>
                    <li>
                      <a href="/ve-chung-toi.html" title="Về chúng tôi">
                        Về chúng tôi
                      </a>
                    </li>
                    <li>
                      <a href="/profile-san-pham" title="Sapo Profile">
                        Sapo Profile
                      </a>
                    </li>
                  </ul>
                </div>

                <div className={styles["item"]}>
                  <h3 className="d-flex align-items-center">
                    <svg><use xlinkHref="#IconDiscover"></use></svg>
                    Khám phá
                  </h3>
                  <ul className={styles["sub-menu"]}>
                    <li>
                      <a href="https://tuyendung.sapo.vn/" title="Tuyển dụng" target="_blank">
                        Tuyển dụng
                      </a>
                    </li>
                    <li>
                      <a href="https://academy.sapo.vn/" title="Sapo Academy" target="_blank">
                        Sapo Academy
                      </a>
                    </li>
                  </ul>
                </div>
                <div className={styles["item"]}>
                  <h3 className="d-flex align-items-center">
                    <svg><use xlinkHref="#IconHelp"></use></svg>
                    Trợ giúp
                  </h3>
                  <ul className={styles["sub-menu"]}>
                    <li>
                      <a href="https://support.sapo.vn/" title="Trợ giúp" target="_blank">
                        Tài liệu hướng dẫn
                      </a>
                    </li>
                    <li>
                      <a href="/lien-he.html" title="Liên hệ">
                        Liên hệ
                      </a>
                    </li>
                  </ul>
                </div>

                <div className={styles["item"]}>
                  <h3 className="d-flex align-items-center">
                    <svg><use xlinkHref="#IconSellingMachine"></use></svg>
                    Thiết bị bán hàng
                  </h3>
                  <ul className={`${styles["sub-menu"]} sub-thiet-bi-ban-hang`}>
                    <li>
                      <a href="https://shop.sapo.vn/" target="_blank">
                        Thiết bị hiện đại - chính hãng - chuyên nghiệp
                      </a>
                    </li>
                    <li>
                      <a href="https://shop.sapo.vn/" target="_blank">
                        <img src={process.env.NEXT_PUBLIC_BASE_PATH + "/images/img-thiet-bi-ban-hang.png?v=2"}
                          alt="Thiết bị bán hàng" />
                      </a>
                    </li>
                  </ul>

                </div>
              </div>
            </li>
            <li className={styles.login}>
              <a href="https://www.sapo.vn/dang-nhap-kenh-ban-hang.html">Đăng nhập</a>
            </li>
          </ul>
        </div>
        <div className={styles["trial-fixed"]}>
          <div className={styles['btn-register']} dangerouslySetInnerHTML={{ __html: trialBtnMobile }}></div>
        </div>
      </div>
      <div className={`${styles["overlay-menu"]} ${showBackdropMenu ? styles['show'] : ''}`} onClick={closeOver}></div>
      {props.width > 991 && <Nav />}
    </>
  );
};

export default HeaderPc;
