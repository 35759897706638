import classNames from "classnames";
import React, { useState } from "react";
import { ICategory } from "../../model/base";
import styles from "./bannerHeader.module.scss";
import Link from "next/link";
import { useRouter } from "next/router";
import { FormGoogleSheet } from "../FormGoogleSheet";
import { useWindowSize } from "../../util/functionGlobal";

interface IBannerHeaderProps {
  title: string;
  description: string;
  bannerCovid?: boolean;
}

export default function BannerHeader(props: IBannerHeaderProps) {
  const { title, description, bannerCovid } = props;
  const router = useRouter();
  const { width } = useWindowSize();
  return (
    <div
      className={classNames(
        bannerCovid
          ? styles["banner-covid"]
          : router.asPath.includes("/kinh-nghiem-kinh-doanh")
          ? `${styles["kinh-nghiem-kinh-doanh"]} ${styles["banner-header"]}`
          : router.asPath.includes("/kenh-ban-hang")
          ? `${styles["kenh-ban-hang"]} ${styles["banner-header"]}`
          : router.asPath.includes("/quan-ly-ban-hang")
          ? `${styles["quan-ly-ban-hang"]} ${styles["banner-header"]}`
          : router.asPath.includes("/ebook")
          ? `${styles["ebook"]} ${styles["banner-header"]}`
          : router.asPath.includes("/cong-nghe")
          ? `${styles["kinh-nghiem-kinh-doanh"]} ${styles["banner-header"]}`
          : router.asPath.includes("/tin-su-kien-sapo")
          ? `${styles["tin-tuc-sapo"]} ${styles["banner-header"]}`
          : styles["banner-header"]
      )}
      onClick={!bannerCovid && width <= 767 ? () => router.push("/") : null}
    >
      {bannerCovid ? (
        ""
      ) : (
        <>
          <div className="container">
            <div className={styles.title}>
              {router.asPath == "/" ? (
                <>
                  <h1>
                    <Link href="/">
                      Chia sẻ kinh nghiệm kinh doanh hiệu quả{" "}
                      <br className="d-none d-lg-block" />
                      giúp nhà bán hàng có thêm 90 phút mỗi ngày
                    </Link>
                  </h1>
                </>
              ) : router.asPath.includes("/kinh-nghiem-kinh-doanh") ? (
                <>
                  <h2>
                    <Link href="/">
                      Cập nhật xu hướng thị trường
                      <br className="d-none d-lg-block" />
                      và kinh nghiệm kinh doanh thực tiễn
                    </Link>
                  </h2>
                  <FormGoogleSheet />
                </>
              ) : router.asPath.includes("/kenh-ban-hang") ? (
                <>
                  <h2>
                    <Link href="/">
                      Chiến lược bán hàng đa kênh
                      <br className="d-none d-lg-block" />
                      giúp tăng trưởng doanh thu hiệu quả
                    </Link>
                  </h2>
                  <FormGoogleSheet />
                </>
              ): router.asPath.includes("/cong-nghe") ? (
                <>
                  <h2>
                    <Link href="/">
                     Kiến thức công nghệ mới nhất
                      <br className="d-none d-lg-block" />
                      giúp bán hàng đa kênh hiệu quả
                    </Link>
                  </h2>
                  <FormGoogleSheet />
                </>
              ) : router.asPath.includes("/quan-ly-ban-hang") ? (
                <>
                  <h2>
                    <Link href="/">
                      Quy trình vận hành và
                      <br className="d-none d-lg-block" />
                      quản lý bán hàng chuyên nghiệp
                    </Link>
                  </h2>
                  <FormGoogleSheet />
                </>
              ) : router.asPath.includes("/ebook") ? (
                <>
                  <h2>
                    <Link href="/">
                      Trọn bộ bí quyết làm chủ
                      <br className="d-none d-lg-block" />
                      công việc kinh doanh đa ngành hàng
                    </Link>
                  </h2>
                  <FormGoogleSheet />
                </>
              ) : router.asPath.includes("/tin-su-kien-sapo") ? (
                <>
                  <h2>
                    <Link href="/">
                      Cập nhật các tin tức, sự kiện
                      <br className="d-none d-lg-block" />
                      và tính năng mới nhất từ Sapo
                    </Link>
                  </h2>
                  <FormGoogleSheet />
                </>
              ) : router.asPath.includes("/?page=") ? (
                <>
                  <h1>
                    <Link href="/">
                      Chia sẻ kinh nghiệm kinh doanh hiệu quả{" "}
                      <br className="d-none d-lg-block" />
                      giúp nhà bán hàng có thêm 90 phút mỗi ngày
                    </Link>
                  </h1>
                </>
              ) : router.asPath.includes("/?query=") ? (
                <>
                  <h1>
                    <Link href="/">
                      Chia sẻ kinh nghiệm kinh doanh hiệu quả{" "}
                      <br className="d-none d-lg-block" />
                      giúp nhà bán hàng có thêm 90 phút mỗi ngày
                    </Link>
                  </h1>
                </>
              ) : router.asPath.includes("/tags") ? (
                <>
                  <h1>
                    <Link href="/">
                      Chia sẻ kinh nghiệm kinh doanh hiệu quả{" "}
                      <br className="d-none d-lg-block" />
                      giúp nhà bán hàng có thêm 90 phút mỗi ngày
                    </Link>
                  </h1>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
}

interface BannerHeaderBreadcrumbProps {
  breadcrumb: ICategory[];
  title: string;
  modDate: string;
  view?: string;
  img: string;
  createdBy?: string;
}

export function BannerHeaderBreadcrumb(props: BannerHeaderBreadcrumbProps) {
  const { breadcrumb, title, modDate, view, img, createdBy } = props;
  const [breadcrumbReverse] = useState<ICategory[]>(breadcrumb.reverse());
  return (
    <div className={styles["image-feature"]} style={{ background: `url(${img})` }}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-8 col-lg-10 col-12">
            <div className={styles["breadcrumb"]}>
              <ul className={styles["clearfix"]}>
                {breadcrumbReverse.map((bc, index) => (
                  <li key={index}>
                    {bc?.alias?.includes("/") ? (
                      <Link href={`${process.env.NEXT_PUBLIC_BASE_URL + process.env.NEXT_PUBLIC_BASE_PATH}/${bc?.alias}`} title={bc.title}>
                        {bc.title ?? "Blog"}
                      </Link>
                    ) : (
                      <Link href={bc?.alias ? "/" + bc?.alias : "/"} title="Blog">
                        {bc?.title ?? "Blog"}
                      </Link>
                    )}
                  </li>
                ))}
              </ul>
            </div>
            <h1>{title}</h1>
            <div className={classNames(styles["post-info"], "d-md-flex align-items-center")}>
              <div className={styles["time-view"]}>
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="far"
                  data-icon="clock"
                  className="svg-inline--fa fa-clock fa-w-16"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="currentColor"
                    d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm61.8-104.4l-84.9-61.7c-3.1-2.3-4.9-5.9-4.9-9.7V116c0-6.6 5.4-12 12-12h32c6.6 0 12 5.4 12 12v141.7l66.8 48.6c5.4 3.9 6.5 11.4 2.6 16.8L334.6 349c-3.9 5.3-11.4 6.5-16.8 2.6z"
                  ></path>
                </svg>
                <span>Cập nhật lần cuối: {modDate}</span>
              </div>
              <div className={styles["time-view"]}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 469.33 320">
                  <g>
                    <path d="M234.67,96a64,64,0,1,0,64,64A64,64,0,0,0,234.67,96Z" />
                    <path d="M234.67,0C128,0,36.91,66.35,0,160c36.91,93.65,128,160,234.67,160s197.76-66.35,234.66-160C432.43,66.35,341.44,0,234.67,0Zm0,266.67A106.67,106.67,0,1,1,341.33,160,106.71,106.71,0,0,1,234.67,266.67Z" />
                  </g>
                </svg>
                <span> {view} Lượt xem</span>
              </div>
              {createdBy && (
                <div className={styles["time-view"]}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    version="1.1"
                    id="Capa_1"
                    x="0px"
                    y="0px"
                    viewBox="0 0 460.8 460.8"
                    xmlSpace="preserve"
                  >
                    <g>
                      <g>
                        <path
                          className="st0"
                          d="M230.4,0c-65.8,0-119.6,53.8-119.6,119.6s53.8,119.6,119.6,119.6s119.6-53.8,119.6-119.6S296.3,0,230.4,0z"
                        />
                      </g>
                    </g>
                    <g>
                      <g>
                        <path
                          className="st0"
                          d="M435.8,334.9c-3.1-7.8-7.3-15.2-12-21.9c-24-35.5-61.1-59-102.9-64.8c-5.2-0.5-11,0.5-15.2,3.7    c-21.9,16.2-48.1,24.6-75.2,24.6s-53.3-8.4-75.2-24.6c-4.2-3.1-9.9-4.7-15.2-3.7c-41.8,5.7-79.4,29.3-102.9,64.8    c-4.7,6.8-8.9,14.6-12,21.9c-1.6,3.1-1,6.8,0.5,9.9c4.2,7.3,9.4,14.6,14.1,20.9c7.3,9.9,15.2,18.8,24,27.2    c7.3,7.3,15.7,14.1,24,20.9c41.3,30.8,90.9,47,142.1,47s100.8-16.2,142.1-47c8.4-6.3,16.7-13.6,24-20.9c8.4-8.4,16.7-17.2,24-27.2    c5.2-6.8,9.9-13.6,14.1-20.9C436.8,341.7,437.3,338,435.8,334.9z"
                        />
                      </g>
                    </g>
                  </svg>
                  <span>Đăng bởi: {createdBy.split("#")[0]} </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
