import { useEffect, useState } from "react";
import { ICategory, ICateList } from "../model/categories";

export function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}
export function useOnClickOutsideModal(ref, handler, clas: string) {
  useEffect(() => {
    const listener = (event) => {
      if (
        !ref.current ||
        ref.current.contains(event.target) ||
        event.path?.filter((i) => {
          if (i.className?.length > 0) return i.className?.includes(clas);
        }).length !== 0
      ) {
        return;
      }
      handler(event);
    };

    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler, clas]);
}

export function useWindowPosition() {
  const [scrollPosition, setPosition] = useState(0);
  useEffect(() => {
    function updatePosition() {
      setPosition(window.pageYOffset);
    }
    window.addEventListener("scroll", updatePosition);
    updatePosition();
    return () => window.removeEventListener("scroll", updatePosition);
  }, []);
  return scrollPosition;
}
export function mergeByProperty(target, source, prop, sourceProp) {
  let re = [];
  source.forEach((sourceElement) => {
    let targetElement = target.find((targetElement) => {
      return sourceElement[sourceProp] === targetElement[prop];
    });
    re.push(targetElement);
  });
  return re;
}

export function fixParams(sourceURL, key) {
  var rtn = sourceURL.split("?")[0],
    param,
    params_arr = [],
    queryString = sourceURL.indexOf("?") !== -1 ? sourceURL.split("?")[1] : "";
  if (queryString !== "") {
    params_arr = queryString.split("&");
    for (var i = params_arr.length - 1; i >= 0; i -= 1) {
      param = params_arr[i].split("=")[0];
      if (param === key) {
        params_arr.splice(i, 1);
      }
    }
    if (params_arr.length) rtn = rtn + "?" + params_arr.join("&") + "&";
    else {
      rtn = rtn + "?";
    }
  }
  else {
    rtn = rtn + "?";
  }
  return rtn;
  // window.history.pushState(null, null, newUrl);
}
export function parse_query_string(query, props) {
  var aliasParams = query.includes("?") ? query.split("?")[1] : query;
  var vars = aliasParams.split("&");
  var query_string = {};
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    var key = decodeURIComponent(pair[0]).replace("/?", "");
    var value = decodeURIComponent(pair[1]);
    // If first entry with this name
    if (typeof query_string[key] === "undefined") {
      query_string[key] = decodeURIComponent(value);
      // If second entry with this name
    } else if (typeof query_string[key] === "string") {
      var arr = [query_string[key], decodeURIComponent(value)];
      query_string[key] = arr;
      // If third or later entry with this name
    } else {
      query_string[key].push(decodeURIComponent(value));
    }
  }

  return query_string[props] || 1;
}
export function round(num: number, decimalPlaces: number) {
  if (num < 0)
    return -round(-num, decimalPlaces);
  var p = Math.pow(10, decimalPlaces);
  var n = num * p;
  var f = n - Math.floor(n);
  var e = Number.EPSILON * n;

  // Determine whether this fraction is a midpoint value.
  return (f >= .5 - e) ? Math.ceil(n) / p : Math.floor(n) / p;
}
export const classDivisionCate = (listCategory: ICategory[]) => {
  var map = {},
    node;

  for (var i = 0; i < listCategory.length; i++) {
    map[listCategory[i].id] = i;
    listCategory[i].kids = [];
  }
  for (var i = 0; i < listCategory.length; i++) {
    node = listCategory[i];
    if (node.parent_id !== null) {
      if (listCategory[map[node.parent_id]].kids !== null && listCategory[map[node.parent_id]].kids !== undefined) {
        listCategory[map[node.parent_id]].kids.push(node);
      } else {
        listCategory[map[node.parent_id]].kids = [];
        listCategory[map[node.parent_id]].kids.push(node);
      }
    }
  }
  return listCategory.filter(x => !x.parent_id);
};
export function getPathAndQuery(resolvedUrl: string) {
  var path = resolvedUrl;
  var query = "";
  if (resolvedUrl.indexOf("?") > -1) {
    path = resolvedUrl.substring(0, resolvedUrl.indexOf("?"));
    query = "?" + resolvedUrl.split("?")[1];
  }
  return { path: path, query: query };
}
export const classDivisionCateMap = (listCategory: ICategory[], _id: number) => {
  let list: ICateList[] = [];
  listCategory?.forEach((item) => {
    if (item.depth !== 0 && !item.has_child) {
      const arrayItem: number[] = item.lineage?.substring(1, item.lineage.length - 1)?.split('/')?.map(item => Number(item));
      arrayItem.push(item.id);
      if (arrayItem?.includes(_id)) {
        list?.push({
          root_id: arrayItem[0],
          value: arrayItem
        })
      }
    }
  });
  let presentCategory: number[] = [];
  if (list.length === 0) {
    return [_id]
  }
  list?.forEach((item) => {
    let index = item.value.indexOf(_id);
    let items: number[] = item.value.slice(index, item.value.length);
    presentCategory = presentCategory?.concat(items);
  });
  let arr = presentCategory?.filter(function (value, index, array) {
    return presentCategory?.indexOf(value) == index;
  });
  return arr || [];

};
export function stripHtml(content: string) {
  const strippedString = content.replace(/(<([^>]+)>)/gi, "");
  return strippedString;
}
export const truncate = (str, max = 20) => {
  const array = str.trim().split(' ');
  const ellipsis = array.length > max ? '...' : '';

  return array.slice(0, max).join(' ') + ellipsis;
};


// Xử lý Ads
export const findIdAds = content => {
  const re = /\[the_ad id="[0-9]+"\]|\[the_ad id=&quot;[0-9]+&quot;\]/gm;
  const reId = /\d+/gm;
  const m = content.match(re);
  let listId = [];
  if (m) {
    m.forEach(element => {
      let id = element.match(reId);
      listId.push(parseInt(id)); ``
    });
  }
  return listId;
}
export const findAdsByIds = (listAds, listId) => {
  const listAdsFinal = [];
  listAds.forEach(item => {
    const itemAds = listId.find(id => id === item.id);
    if (itemAds) {
      listAdsFinal.push(item);
    }
  });
  return listAdsFinal;
}
export const addAdsToContent = (listAds, content) => {
  let contentFinal = content.toString();
  listAds.forEach(element => {
    let regex = new RegExp('(\\[the_ad id="' + element.id + '"\\])|(\\[the_ad id=&quot;' + element.id + '&quot;\\])', 'gm');
    contentFinal = contentFinal.replace(regex, element.content);
  });
  return contentFinal;
}
export const getAdsWithCondition = (tagIds, categoriesIds, listAds) => {
  let listAdsFirst = [];
  let listAdsFinal = [];
  if (listAds && listAds.length > 0) {
    for (let i = 0; i < listAds.length; i++) {
      let adsItem = listAds[i];
      let isEligible = false;
      if (tagIds && tagIds.length > 0) {
        if (adsItem.tag_in) {
          let tagIn = adsItem.tag_in.split(",");
          for (let j = 0; j < tagIds.length; j++) {
            let isExits = tagIn.some(id => parseInt(id) === tagIds[j]);
            if (isExits) {
              isEligible = true;
              break;
            }
          }
        }
      }
      if (!isEligible && categoriesIds && categoriesIds.length > 0) {
        if (adsItem.cat_in) {
          let catIn = adsItem.cat_in.split(",");
          for (let j = 0; j < categoriesIds.length; j++) {
            let isExits = catIn.some(id => parseInt(id) === categoriesIds[j]);
            if (isExits) {
              isEligible = true;
              break;
            }
          }
        }
      }
      if (isEligible) {
        listAdsFirst.push(adsItem);
      }
    }
    for (let i = 0; i < listAdsFirst.length; i++) {
      let adsItem = listAdsFirst[i];
      let isEligibleTag = true;
      let isEligibleCat = true;
      if (adsItem.tag_not_in) {
        let tagNotIn = adsItem.tag_not_in.split(",");
        for (let j = 0; j < tagIds.length; j++) {
          let isExits = tagNotIn.some(id => parseInt(id) === tagIds[j]);
          if (isExits) {
            isEligibleTag = false;
            break;
          }
        }
      }
      if (isEligibleTag && adsItem.cat_not_in) {
        let catNotIn = adsItem.cat_not_in.split(",");
        for (let j = 0; j < categoriesIds.length; j++) {
          let isExits = catNotIn.some(id => parseInt(id) === categoriesIds[j]);
          if (isExits) {
            isEligibleCat = false;
            break;
          }
        }
      }
      if (isEligibleTag && isEligibleCat) {
        listAdsFinal.push(adsItem);
      }
    }
  }
  return listAdsFinal;
}

export function convertSlugs(str: string, allowPath?: boolean) {
  if (str) {
    return trimChar(removeVietnameseTones(str, allowPath).split(/\s+/).join('-'), '-');
  } else {
    return '';
  }
}
export function trimChar(str: string, ch: string) {
  var start = 0,
    end = str.length;

  while (start < end && str[start] === ch) ++start;

  while (end > start && str[end - 1] === ch) --end;

  return start > 0 || end < str.length ? str.substring(start, end) : str;
}
export function removeVietnameseTones(str: string, allowPath?: boolean) {
  if (str) {
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
    str = str.replace(/đ/g, 'd');
    str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, 'A');
    str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, 'E');
    str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, 'I');
    str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, 'O');
    str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, 'U');
    str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, 'Y');
    str = str.replace(/Đ/g, 'D');
    // Some system encode vietnamese combining accent as individual utf-8 characters
    // Một vài bộ encode coi các dấu mũ, dấu chữ như một kí tự riêng biệt nên thêm hai dòng này
    str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ''); // ̀ ́ ̃ ̉ ̣  huyền, sắc, ngã, hỏi, nặng
    str = str.replace(/\u02C6|\u0306|\u031B/g, ''); // ˆ ̆ ̛  Â, Ê, Ă, Ơ, Ư
    // Remove extra spaces
    // Bỏ các khoảng trắng liền nhau
    str = str.replace(/ + /g, ' ');
    str = str.trim();
    // Remove punctuations
    // Bỏ dấu câu, kí tự đặc biệt
    if (allowPath) {
      str = str.replace(/!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g, ' ');
    } else {
      str = str.replace(/!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g, ' ');
    }
  }

  return str;
}

export function getDataFromUrl( name: string, url: string ) {
  if (!url) url = location.href;
  name = name.replace(/[\[]/,"\\\[").replace(/[\]]/,"\\\]");
  var regexS = "[\\?&]"+name+"=([^&#]*)";
  var regex = new RegExp( regexS );
  var results = regex.exec( url );
  return results == null ? null : results[1];
}