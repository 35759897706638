import classNames from "classnames";
import React from "react";
import styles from "./footer.module.scss";
import Image from "next/legacy/image";
const FooterCopyright = () => {
  return (
    <div className={styles["footer-copyright"]}>
      <div className="container">
        <div className="row">
          <div className="col-lg-7 col-md-6 order-md-1 order-3">
            <p className={styles.copyright}>
              <span className="d-none d-lg-inline-block">Copyright © 2021&nbsp;</span>
              <a href="https://www.sapo.vn" target="_blank" rel="noopener noreferrer">
                Sapo.vn
              </a>{" "}
              - Nền tảng quản lý và bán hàng đa kênh được sử dụng nhiều nhất Việt Nam
            </p>
          </div>
          <hr style={{ border: "1px solid #24282e", borderTopColor: "#000", width: "100%", margin: 0 }} className="d-block d-sm-none order-2" />
          <div className="col-lg-5 col-md-6 order-md-2 order-1">
            <div className={styles.achievement}>
              <span>
                Sản phẩm đạt giải: <br className="d-block d-lg-none" />
                Nhân tài Đất Việt 2013 & Sao Khuê 2015
              </span>
              <div className={styles["icon-achievement"]}>
                <Image src={process.env.NEXT_PUBLIC_BASE_PATH + "/images/icon-cup.png"} alt="Giải thưởng" width="85" height="28" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <p className={classNames(styles["copyright-mobile"], "d-lg-none d-md-block d-none")}>© Copyright 2008 - 2021</p>
    </div>
  );
};

export default FooterCopyright;
